import React from 'react';
import {Avatar} from "antd";
import {UserOutlined} from '@ant-design/icons';
import {HolotrakUser} from "../../contracts/holotrak/holotrakUser";

interface IUserAvatarProps {
    user: HolotrakUser;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({user}) => {
    const size = 32;
    const userCustomsEnabled = false;

    return (userCustomsEnabled && user?.avatar) ? (
        <Avatar src={user.avatar} size={size}/>
    ) : (
        <Avatar icon={<UserOutlined/>} size={size}/>
    );
}
