import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, Layout, Menu, Typography} from "antd";
import {RightOutlined} from "@ant-design/icons";


import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {ThemeConfig} from "antd/es/config-provider/context";
import {COLOR_DARK, COLOR_LIGHT, COLOR_PRIMARY} from "../../App";

const {Content, Sider} = Layout;
const {Title} = Typography;

const HolotrakSettingsLayout: React.FC = () => {
    const navigate = useNavigate();
    const currentLocation = useLocation();
    const [currentRoute, setCurrentRoute] = useState('general');
    useEffect(() => {
        let [, , route] = currentLocation.pathname.split('/');

        if (!route) {
            route = 'general';
        }

        setCurrentRoute(route);
    }, [currentLocation.pathname]);

    const themeConfig: ThemeConfig = useMemo<ThemeConfig>(() => {
        const themeConfig: ThemeConfig = {
            components: {
                "Layout": {
                    "colorBgContainer": COLOR_LIGHT,
                    "colorBgHeader": '#efefef',
                },
                Menu: {
                    "itemBg": COLOR_LIGHT,
                    "itemColor": COLOR_DARK,
                    "itemHoverBg": COLOR_LIGHT,
                    "itemHoverColor": COLOR_PRIMARY,
                    "itemSelectedBg": COLOR_LIGHT,
                }
            }
        };

        return themeConfig;
    }, []);


    const handleMenuClick = useCallback((e: any) => {
        navigate(`/settings/${e.key}`);
    }, [navigate]);


    const menu = useMemo(() => ({
        settings: [
            {
                key: 'organization',
                label: 'Organization',
                type: 'group',
                children: [
                    {
                        key: "general",
                        label: "General",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "roles",
                        label: "Role Management",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "users",
                        label: "User Management",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "groups",
                        label: "Groups Management",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "recipients",
                        label: "Recipient Management",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "activity",
                        label: "Activity Log",
                        icon: <RightOutlined/>,
                    },
                ]
            },
            {
                key: 'sep',
                type: 'divider'
            },
            {
                key: 'systems',
                label: 'Systems',
                type: 'group',
                children: [
                    {
                        key: "devices",
                        label: "Device Settings",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "geofence",
                        label: "Geofence Settings",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "alerts",
                        label: "Device Alerts",
                        icon: <RightOutlined/>,
                    },
                    {
                        key: "sensor-alerts",
                        label: "Sensor Alerts",
                        icon: <RightOutlined/>,
                    },
                ]
            }
        ]
    }), []);

    return (
        <ConfigProvider theme={themeConfig}>
            <Layout hasSider>
                <Sider
                    width={170}
                    className={"holotrak-sider holotrak-sider-settings holotrak-sider-settings--main"}
                    theme={"light"}
                >
                    <Title level={3}>
                        Settings
                    </Title>
                    <Menu
                        className={"holotrak-settings-menu"}
                        mode="inline"
                        selectedKeys={[currentRoute]}
                        onClick={handleMenuClick}
                        items={menu.settings}
                    />
                </Sider>
                <Content
                    style={{
                        height: '100vh',
                    }}
                >
                    <Outlet/>
                </Content>
            </Layout>
        </ConfigProvider>
    );
}

export default HolotrakSettingsLayout;
