import React, {useCallback, useMemo, useState} from 'react';
import {Button, Checkbox, DatePicker, Drawer, Form, Input, notification, Radio, Space, Steps} from "antd";
import {IconGraphLine} from "../../assets/icons/icon-graph-line";
import {IconThunder} from "../../assets/icons/icon-thunder";
import {IconDone} from "../../assets/icons/icon-done";

import './report-creation.scss';
import {DevicePicker} from "../group-items/DevicePicker";
import AccessControl from "../core/AccessControl";
import dayjs from "dayjs";
import {RadioChangeEvent} from "antd/es/radio/interface";
import {useCreateReportMutation} from "../../redux/services/express/reports";
import {IReportCreation, Report} from "contracts/holotrak/report";
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";

interface IReportCreationProps {
    toggleReportCreation: () => void;
    isCreatingReport: boolean;
}

const {RangePicker} = DatePicker;


const ReportCreation: React.FC<IReportCreationProps> = ({toggleReportCreation, isCreatingReport}) => {
    const [reportCreationForm] = Form.useForm<Report>();

    const [saveReportTrigger, {isLoading: isSavingReport}] = useCreateReportMutation();

    const saveReport = () => {
        reportCreationForm
            .validateFields()
            .then((values) => {
                const startDate = dayjs(reportCreationForm.getFieldValue('startDate'));
                const endDate = dayjs(reportCreationForm.getFieldValue('endDate'));
                const controlDays = reportCreationForm.getFieldValue('controlDays');
                const reportTitle = reportCreationForm.getFieldValue('reportTitle');
                const reportIndex = reportCreationForm.getFieldValue('reportIndex');

                const devices = reportCreationForm.getFieldValue('devices');

                const filterValues = {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    controlDays: controlDays,
                    startTime: startDate.format('HH:mm A'),
                    endTime: endDate.format('HH:mm A'),
                    startDateRange: startDate.toISOString(),
                    endDateRange: endDate.toISOString(),
                    reportTitle,
                    reportIndex,
                };

                const report: IReportCreation = {
                    devices: JSON.stringify(devices),
                    filters: JSON.stringify(filterValues),
                    ...filterValues,
                };


                saveReportTrigger(report).then(() => {

                    reportCreationForm.resetFields();
                    toggleReportCreation();
                    notification.success({
                        message: 'Report Created',
                        description: `Report ${reportTitle} has been created successfully.`,
                    });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const refReportType = React.useRef<HTMLDivElement>(null);
    const refReportDevices = React.useRef<HTMLDivElement>(null);
    const refReportDetails = React.useRef<HTMLDivElement>(null);

    const availableReportTypes = useMemo(() => ([
        'Summary Report',
        'Alert Report',
        'Start and Stop Report',
        'Trip History Report - All Events Data for a Trip'
    ]), []);

    const setReportTitle = useCallback((e: RadioChangeEvent) => {
        reportCreationForm.setFieldValue('reportTitle', availableReportTypes[e.target.value - 1]);
    }, [availableReportTypes, reportCreationForm]);


    const reportCreationSteps = useMemo(() => ([
        {
            title: 'Select Report Type',
            key: 'reportIndex',
            icon: <IconGraphLine/>,
            content: (
                <div ref={refReportType}>
                    <Form.Item
                        name={'reportIndex'}
                        label="Report Type"
                        rules={[
                            {
                                required: true,
                                message: "Please select Report Type!"
                            }
                        ]}
                    >
                        <Radio.Group onChange={setReportTitle}>
                            {
                                availableReportTypes.map((type, index) => (
                                    <Radio.Button key={index} value={index + 1}>{type}</Radio.Button>
                                ))
                            }
                        </Radio.Group>
                    </Form.Item>
                </div>
            )
        },
        {
            title: 'Add Devices',
            key: 'devices',
            icon: <IconThunder/>,
            content: (
                <Form.Item
                    shouldUpdate
                    rules={[
                        {
                            required: true,
                            message: "Please select Devices!"
                        },
                        {
                            validator: (rule, value) => {
                                if (value.length === 0) {
                                    return Promise.reject('Please select at least one device!');
                                }
                            }
                        }
                    ]}
                >
                    {
                        (form) => (
                            <div ref={refReportDevices}>
                                <div
                                    className={`ant-form-item-label ${form.getFieldError('devices') ? 'has-error' : ''}`}>
                                    <label
                                        htmlFor="devices"
                                        className=""
                                        title="Devices">
                                        Devices
                                    </label>
                                </div>
                                <DevicePicker name={'devices'} form={reportCreationForm}/>
                            </div>
                        )
                    }
                </Form.Item>
            )
        },
        {
            title: 'Details',
            key: 'finalize',
            icon: <IconDone/>,
            content: (
                <div ref={refReportDetails}>
                    <Form.Item
                        className="last-select"
                        label="Report title"
                        name="reportTitle"
                        rules={[
                            {
                                required: true,
                                message: "Please input Report title!"
                            },
                            {
                                min: 3,
                                message: "Report title must be at least 3 characters long!"
                            }
                        ]}
                    >
                        <Input placeholder="Report Title"/>
                    </Form.Item>

                    <Form.Item
                        label="Date Range"
                        dependencies={['startDate', 'endDate']}
                        rules={[
                            {
                                required: true,
                                message: "Please select Date Range!"
                            },
                            {
                                validator: (rule, value) => {
                                    if (value[0] && value[1]) {
                                        if (dayjs(value[0]).isAfter(value[1])) {
                                            return Promise.reject('Start date must be before end date!');
                                        }
                                    }
                                }
                            }
                        ]}
                    >
                        {
                            (form) => (
                                <RangePicker
                                    showTime
                                    value={[
                                        dayjs(form.getFieldValue('startDate')),
                                        dayjs(form.getFieldValue('endDate'))
                                    ]}

                                    onChange={(date, dateString) => {
                                        form.setFieldsValue({
                                            startDate: dateString[0],
                                            endDate: dateString[1]
                                        })
                                    }}
                                />
                            )
                        }
                    </Form.Item>

                    <Form.Item
                        name="controlDays"
                        label="Control days"
                        rules={[
                            {
                                required: true,
                                message: "Please select Control days!"
                            }
                        ]}
                    >
                        <Checkbox.Group
                            className="control-days"
                        >
                            <Checkbox value={1}>M</Checkbox>
                            <Checkbox value={2}>T</Checkbox>
                            <Checkbox value={3}>W</Checkbox>
                            <Checkbox value={4}>T</Checkbox>
                            <Checkbox value={5}>F</Checkbox>
                            <Checkbox value={6}>S</Checkbox>
                            <Checkbox value={0}>S</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            )
        },
    ]), [availableReportTypes, reportCreationForm, setReportTitle]);
    const [currentStep, setCurrentStep] = useState(0);
    const items = reportCreationSteps.map((item) => ({key: item.key, title: item.title, icon: item.icon}));

    const nextStep = useCallback(() => {
        setCurrentStep((currentStep) => {
            const reportRef = [refReportType, refReportDevices, refReportDetails];
            const finalStep = currentStep + 1;

            if (reportRef[finalStep].current) {
                reportRef[finalStep].current.scrollIntoView({behavior: 'smooth'});

            }

            return finalStep;
        });
    }, []);
    const prevStep = useCallback(() => {
        setCurrentStep((currentStep) => {
            const reportRef = [refReportType, refReportDevices, refReportDetails];
            const finalStep = currentStep - 1;

            if (reportRef[finalStep].current) {
                reportRef[finalStep].current.scrollIntoView({behavior: 'smooth'});

            }

            return finalStep;
        });
    }, []);

    return (
        <Drawer
            open={isCreatingReport}
            width={800}
            className="report-creation"
            placement="right"
            closable={true}
            onClose={() => {
                reportCreationForm.resetFields();
                toggleReportCreation();
                setCurrentStep(0);
            }}
            title={(
                <>
                    <Steps current={currentStep} items={items}/>
                </>
            )}
            footer={(
                <Space>
                    {currentStep > 0 && (
                        <Button onClick={() => prevStep()}>
                            Previous
                        </Button>
                    )}

                    {currentStep < reportCreationSteps.length - 1 && (
                        <Button type="primary" onClick={() => nextStep()}>
                            Next
                        </Button>
                    )}

                    {currentStep === reportCreationSteps.length - 1 && (

                        <AccessControl
                            sidebar
                            resource={EResource.REPORTS}
                            permissionNeeded={EPermission.CREATE}

                            render={p => (
                                <Button
                                    onClick={saveReport}
                                    loading={isSavingReport}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Build Report
                                </Button>
                            )}
                        />
                    )}
                </Space>
            )}
        >
            <Form
                form={reportCreationForm}
                initialValues={{
                    startDate: dayjs().subtract(1, 'month').startOf('day'),
                    endDate: dayjs().endOf('day'),
                    controlDays: [],
                    devices: []
                }}
            >
                <>
                    {
                        reportCreationSteps.map((step, index) => (
                            <div key={reportCreationSteps[index].key}>
                                {reportCreationSteps[index].content}
                            </div>
                        ))
                    }
                </>
            </Form>
        </Drawer>
    )
}

export default ReportCreation;
