import {ProductConfigResponse} from "contracts/ProductConfigResponse";
import {useFetchProductConfigsQuery} from "redux/services/losant/sensors";

interface ISensorModelNameHookResponse {
    modelName: string;
    error: any;
    isLoading: boolean;
    data: ProductConfigResponse;
}


export function useSensorModelName(model: string) : ISensorModelNameHookResponse {
    const {data, error, isLoading} = useFetchProductConfigsQuery();

    return {
        modelName: (!isLoading)
            ? data?.externalSensorModels.find((modelConfig) => (modelConfig.id === model))?.label
            : '-',
        error,
        isLoading,
        data
    }
}
