import React from 'react';
import type {TabsProps} from 'antd';
import {DatePicker, Form, Input, Tabs} from "antd";


interface IDriverTabbedFormProps {
}

const DriverTabbedForm: React.FC<IDriverTabbedFormProps> = (props) => {

    const items: TabsProps['items'] = [
        {
            key: 'profile',
            label: `Profile`,
            children: (
                <>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please Input Driver Name!",
                            },
                        ]}
                    >
                        <Input placeholder="Name"/>
                    </Form.Item>


                    <Form.Item label="Select Department" name="department">
                        <Input placeholder="Select Department"/>
                    </Form.Item>

                    <Form.Item label="Tags" name="tags">
                        <Input placeholder="Tags"/>
                    </Form.Item>
                </>
            ),
        },
        {
            key: 'driverDetails',
            label: `Driver Details`,
            children: (
                <>

                    <Form.Item label="Issuing Authority" name="issuingAuthority">
                        <Input placeholder="Issuing Authority"/>
                    </Form.Item>

                    <Form.Item label="Drivers Licence Number" name="driverLicenceNumber">
                        <Input placeholder="Drivers Licence Number"/>
                    </Form.Item>

                    <Form.Item label="Licence Class" name="licenceClass">
                        <Input placeholder="Licence Class"/>
                    </Form.Item>

                    <Form.Item label="Expiration Date" dependencies={["expirationDate"]}>
                        {
                            (form) => (
                                <DatePicker
                                    defaultValue={form.getFieldValue("expirationDate")}
                                    onChange={(date, dateString) => {
                                        form.setFieldsValue({expirationDate: dateString});
                                    }}
                                />
                            )
                        }
                    </Form.Item>
                </>
            ),
        },
        {
            key: 'contactInfo',
            label: `Contact Info`,
            children: (
                <>
                    <Form.Item label="Phone Number" name="phone">
                        <Input type={"tel"} placeholder="Phone Number "/>
                    </Form.Item>

                    <Form.Item label="Email" name="email">
                        <Input type={"email"} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item label="Location" name="location">
                        <Input placeholder="Location"/>
                    </Form.Item>
                </>
            ),
        }
    ];


    return (
        <>
            <Tabs defaultActiveKey="profile" items={items}/>
        </>
    );
}


export default DriverTabbedForm;
