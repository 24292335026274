import {expressApi} from "./index";
import {IAWSSignedUrlResponse} from "../../../contracts/IAWSSignedUrlResponse";
import {IFileUploadResponse} from "../../../contracts/IFileUploadResponse";


const filesApi = expressApi.injectEndpoints({
    endpoints: (builder) => ({
        // GeoFence Files
        getSignedUrl: builder.query<IAWSSignedUrlResponse, string>({
            keepUnusedDataFor: 15 * 60,
            extraOptions: {
                pollingInterval: 10 * 60 * 1000, // URLs expire every 10 minutes.
            },
            query: (fileName) => ({
                url: `geofence/${fileName}`,
                method: 'GET',
            }),
        }),
        getSignedUrlForPlan: builder.query<IAWSSignedUrlResponse, string>({
            keepUnusedDataFor: 15 * 60,
            extraOptions: {
                pollingInterval: 10 * 60 * 1000, // URLs expire every 10 minutes.
            },
            query: (fileName) => ({
                url: `${fileName}`,
                method: 'GET',
            }),
        }),
        uploadFile: builder.mutation<IFileUploadResponse, File>({
            query: (file) => ({
                url: `geofence/upload`,
                method: 'POST',
            }),
        }),
        deleteFile: builder.mutation<void, string>({
            query: (fileName) => ({
                url: `geofence/${fileName}`,
                method: 'DELETE',
            }),
        }),
        deletePlanFile: builder.mutation<void, string>({
            query: (fileName) => ({
                url: `${fileName}`, // Since the URL will already be containing plans
                method: 'DELETE',
            }),
        }),
    })
});

export const {
    useGetSignedUrlQuery,
    useGetSignedUrlForPlanQuery,
    useUploadFileMutation,
    useDeleteFileMutation,
    useDeletePlanFileMutation,
} = filesApi;
