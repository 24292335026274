import {jsonMember, jsonObject, TypedJSON} from "typedjson";

@jsonObject()
export class DeviceAttribute {
    @jsonMember(String)
    name: string

    @jsonMember(String)
    dataType: string

    @jsonMember(String)
    description: string
}

@jsonObject
export class DeviceTag {
    @jsonMember(String)
    key: string
    @jsonMember(String)
    value: string
}

export const DeviceTagSerializer = new TypedJSON(DeviceTag);
