import React, {useCallback} from 'react';
import {Badge, Button, Checkbox, message, Popconfirm, Space} from "antd";
import {Geofence} from "contracts/holotrak/geofence";
import {useAppDispatch, useTypedSelector} from "../../../redux/store";
import {
    removeGeofenceFromList,
    selectGeofenceDrawingMode,
    setGeofenceDraw,
    setGeofenceKmzKey,
    toggleGeofenceFromList
} from "../../../redux/slices/app";
import {IconTrash} from "../../../assets/icons/icon-trash";
import {IconEdit} from "../../../assets/icons/icon-edit";
import {useDeleteGeofenceMutation} from "../../../redux/services/losant/geofences";
import {useDeleteFileMutation} from "../../../redux/services/express/files";
import AccessControl from "../../core/AccessControl";
import {EPermission} from "../../../contracts/EPermission";
import {EResource} from "../../../contracts/EResource";


interface IGeofenceListItemProps {
    geofence: Geofence;
    style: Object;
    isChecked: boolean;
    onEditClick?: (geofence: Geofence) => void;
    onBadgeClick?: (geofence: Geofence) => void;
}

export const GeofenceListItem: React.FC<IGeofenceListItemProps> = (props) => {
    const {
        isChecked,
        geofence,
        onEditClick,
        style
    } = props;

    const appDispatch = useAppDispatch();

    const isDrawControlEnabled = useTypedSelector(selectGeofenceDrawingMode);


    const toggleGeofence = useCallback((geofence) => {
        appDispatch(toggleGeofenceFromList(geofence));
    }, [appDispatch]);

    const [processGeofenceDeletion] = useDeleteGeofenceMutation();
    const [deleteFileFromServer] = useDeleteFileMutation();

    const editGeofence = useCallback(() => {
        if (!isChecked) {
            appDispatch(toggleGeofenceFromList(geofence));
        }

        appDispatch(setGeofenceDraw(geofence));
        if (geofence.type === 'kml' && geofence.shape) {
            appDispatch(setGeofenceKmzKey(geofence.shape));
        }

        if (onEditClick) {
            onEditClick(geofence);
        }

    }, [isChecked, appDispatch, geofence, onEditClick]);


    const deleteGeofence = useCallback(() => {
        if (geofence.type === 'kml' && geofence.shape) {
            deleteFileFromServer(geofence.shape);
        }

        processGeofenceDeletion(geofence.id).then(() => {
            message.success(`Geofence ${geofence.name} deleted`).then(() => {
                appDispatch(removeGeofenceFromList(geofence));
            });
        });
    }, [appDispatch, geofence, processGeofenceDeletion]);

    return (
        <Space
            className='holotrak-geofence--list-item'
            style={{...style, justifyContent: 'space-between', width: 'calc(100% - 8px)'}}
            direction="horizontal"
        >
            <Checkbox
                checked={isChecked}
                onChange={(ev) => toggleGeofence(geofence)}
                disabled={isDrawControlEnabled}
            >
                {geofence.name}
            </Checkbox>

            <Space>

                <Button
                    ghost
                    shape='circle'
                    size='small'
                    onClick={() => {
                        if (props.onBadgeClick) {
                            props.onBadgeClick(geofence);
                        }
                    }}
                >
                    <Badge
                        count={geofence.locatableItems.length}
                    />
                </Button>


                <AccessControl
                    resource={EResource.GEO_FENCE}
                    permissionNeeded={EPermission.DELETE}
                    sidebar

                    render={p => (
                        <Popconfirm title={"Are you sure?"} onConfirm={deleteGeofence}>
                            <Button
                                disabled={isDrawControlEnabled}
                                ghost
                                shape='circle'
                                icon={<IconTrash/>}
                                size='small'
                            />
                        </Popconfirm>
                    )}
                />

                <AccessControl
                    resource={EResource.GEO_FENCE}
                    permissionNeeded={EPermission.UPDATE}
                    sidebar
                    render={p => (
                        <Button
                            disabled={isDrawControlEnabled}
                            ghost
                            shape='circle'
                            icon={<IconEdit/>}
                            size='small'
                            onClick={editGeofence}
                        />
                    )}
                />

            </Space>

        </Space>
    );
}
