import {jsonMember, jsonObject, TypedJSON} from "typedjson";

@jsonObject()
export class LosantPagination {
    @jsonMember(String)
    sortColumn: string;

    @jsonMember(String)
    sortDirection: string;

    @jsonMember(Number)
    limit?: number;

    @jsonMember(Number)
    offset?: number;

    @jsonMember(Number)
    perPage?: number;


    @jsonMember(Number)
    totalCount: number;

    @jsonMember(Number) private _count?: number;

    get count(): number {
        return this._count ?? this.totalCount;
    }

    set count(value: number) {
        this._count = value;
        this.totalCount = value;
    }
}

export const LosantPaginationSerializer = new TypedJSON(LosantPagination);
