import React from 'react';
import {Checkbox, Drawer, List, Typography} from "antd";

const {Text} = Typography;

interface IColumnConfiguratorProps {
    isConfiguring: boolean;
    toggleConfigurationMode: () => void;
    columns: any[];
    setColumns: (columns: any[]) => void;
}

export const ColumnConfigurator: React.FC<IColumnConfiguratorProps> = ({
                                                                           isConfiguring,
                                                                           toggleConfigurationMode,
                                                                           columns,
                                                                           setColumns
                                                                       }) => {
    return (
        <Drawer
            title="Columns"
            placement="right"
            onClose={toggleConfigurationMode}
            open={isConfiguring}
        >
            <List
                itemLayout="horizontal"
                dataSource={columns}
                renderItem={column => (
                    <List.Item itemID={`checkboxitem-${column.key}`}>
                        <Checkbox
                            key={`checkbox-${column.key}`}
                            className="filter-item"
                            checked={column.column}
                            onChange={(e) => {
                                const newColumns = [...columns];
                                const index = newColumns.findIndex((item) => item.key === column.key);

                                newColumns[index].column = e.target.checked;
                                setColumns(newColumns);
                            }}
                        >
                            <Text
                                className="filter-item__title"
                                ellipsis
                            >
                                {column.title}
                            </Text>
                        </Checkbox>
                    </List.Item>
                )}
            />
        </Drawer>
    )
}

export default ColumnConfigurator;
