import {HoloTrakMapTypes} from "contracts/EHoloTrakMapTypes";
import React, {useCallback} from "react";
import {
    selectGeofenceDrawerOpen,
    selectMapConfig,
    selectPrevMapType,
    setGeofenceDrawer,
    setMapType
} from "redux/slices/app";
import {useAppDispatch, useTypedSelector} from "redux/store";

import "./bottom-bar.scss";
import {Button, Space} from "antd";
import AccessControl from "../core/AccessControl";
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";


interface IBottomBarProps {
    children?: React.ReactNode;
}

export const BottomBar: React.FC<IBottomBarProps> = ({children}) => {
    const dispatch = useAppDispatch();
    const normalMapType = useTypedSelector(selectPrevMapType) || HoloTrakMapTypes.dark;
    const currentMapConfig = useTypedSelector(selectMapConfig);
    const isGeofenceDrawerOpen = useTypedSelector(selectGeofenceDrawerOpen);

    const setMapTypeToNormal = useCallback(() => {
        dispatch(setMapType(normalMapType))
    }, [dispatch, normalMapType]);

    const setMapTypeToSatellite = useCallback(() => {
        dispatch(setMapType(HoloTrakMapTypes.satellite))
    }, [dispatch]);

    const toggleGeoFenceDrawer = useCallback(() => {
        dispatch(setGeofenceDrawer(!isGeofenceDrawerOpen));
    }, [dispatch, isGeofenceDrawerOpen]);

    return (
        <div className='bottom-bar'>
            <Space size={24}>
                <Button
                    className={currentMapConfig.type !== HoloTrakMapTypes.satellite ? `bottom-map` : ''}
                    size='small'
                    onClick={setMapTypeToNormal}
                >
                    Map
                </Button>
                <Button
                    className={currentMapConfig.type === HoloTrakMapTypes.satellite ? `bottom-map` : ''}
                    size='small'
                    onClick={setMapTypeToSatellite}>
                    Satellite
                </Button>


                {children}

                <AccessControl
                    sidebar
                    resource={EResource.GEO_FENCE}
                    permissionNeeded={EPermission.READ}
                    render={accessProps => (
                        <Button size='small' onClick={toggleGeoFenceDrawer}>
                            Add GeoFence/KML Layer(s)
                        </Button>
                    )}
                />
            </Space>
        </div>
    )
}
