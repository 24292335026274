import React, {useMemo} from 'react';
import {Space, Typography} from "antd";
import {useTypedSelector} from "../../redux/store";
import {selectCurrentUser} from "../../redux/slices/auth";
import {HolotrakUserSerializer} from "../../contracts/holotrak/holotrakUser";
import {UserAvatar} from "./UserAvatar";
import Tooltip from "antd/es/tooltip";

const {Text} = Typography;

interface IUserProfileProps {
    isCollapsed?: boolean;
}

export const UserProfile: React.FC<IUserProfileProps> = ({isCollapsed}) => {
    const userInState = useTypedSelector(selectCurrentUser);
    const user = useMemo(() => HolotrakUserSerializer.parse(userInState), [userInState]);

    const {fullName, trimmed} = useMemo(() => {
        if (!user) return {fullName: '', trimmed: false};

        const limit = 7;
        let trimmed = false;
        let fullName = user.fullName;
        if (fullName.length > limit) {
            fullName = `${fullName.slice(0, limit)}...`;
            trimmed = true;
        }

        return {
            trimmed,
            fullName,
        };
    }, [user]);

    return (
        <Space className={`holotrak-user ${isCollapsed ? 'collapsed' : ''}`} size={7}>
            <UserAvatar user={user}/>
            {
                !isCollapsed && (trimmed ? (
                    <Tooltip overlayClassName='holotrak-user-name' title={user.fullName} color={'#fff'} placement='right'>
                        <Text>{fullName}</Text>
                    </Tooltip>
                ) : (
                    <Text>{fullName}</Text>
                ))
            }
        </Space>
    );
}
