import {losantApi} from "./index";
import {ILosantListQuery} from "contracts/ILosantListQuery";
import {TAG_MESSAGE} from "../../../contracts/reduxResourceTags";
import {MessageListResponse} from "../../../contracts/holotrak/message";

const messagesApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getMessagesList: builder.query<MessageListResponse, ILosantListQuery>({
            providesTags: (result) => {
                return [
                    ...result.items.map(fence => ({
                        type: TAG_MESSAGE,
                        id: fence.id
                    })), TAG_MESSAGE
                ];
            },
            query: (queryArgs) => {
                let sortDirection = (queryArgs.sort === 'ascend') ? 'asc' : `desc`;
                let defaultQueryParams = `sortField=createdAt&sortDirection=${sortDirection}`;

                return {
                    url: `/messages?page=${queryArgs.page}&pageSize=${queryArgs.limit}&filterValue=${queryArgs.searchValue}&${defaultQueryParams}`,
                    method: "get",
                };
            }
        }),
    })
});

export const {
    useGetMessagesListQuery
} = messagesApi;
