import {Select} from "antd";
import React, {useMemo} from "react";
import {useGetDeviceReportQuery} from "../../redux/services/losant/devices";


const DevicesDropdown = ({defaultValue = 'Devices', onClear, onSelect}) => {

    const [deviceSearch, setDeviceSearch] = React.useState<string>("");
    const {isLoading: isLoadingDevicesList, data: devicesApiResponse} = useGetDeviceReportQuery({
        page: 0,
        limit: 15,
        fieldValue: '',
        searchValue: deviceSearch,
    });

    const deviceOptions = useMemo(() => {
        const completeOptions = [
            {value: "devices", label: "Devices"}
        ]
        if (!isLoadingDevicesList) {
            devicesApiResponse?.items?.forEach((driver) => {
                completeOptions.push(
                    {
                        value: driver.id,
                        label: driver.name,
                    }
                );
            })
        }

        return completeOptions;
    }, [isLoadingDevicesList, devicesApiResponse?.items]);


    return (
        <Select
            allowClear
            showSearch
            filterOption={false}
            onClear={onClear}
            onSelect={onSelect}
            defaultValue={defaultValue}
            onSearch={(value) => setDeviceSearch(value)}
            options={deviceOptions}
        />
    )
}

export default DevicesDropdown;
