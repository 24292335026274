export enum EDeviceCommandTypes {
    LOCATE = 'locate',
    RECOVER = 'recover',
    RESET = 'reset',

    ACTIVATE_OUTPUT_0 = 'activate_output_0',
    DEACTIVATE_OUTPUT_0 = 'deactivate_output_0',

    ACTIVATE_OUTPUT_1 = 'activate_output_1',
    DEACTIVATE_OUTPUT_1 = 'deactivate_output_1',

    ACTIVATE_RECOVERY_0 = 'activate_recovery_0',
    DEACTIVATE_RECOVERY_0 = 'deactivate_recovery_0',

}

export type OutputSupportedDeviceType = 'calamp' | 'atrack' | 'Yabby Edge' | 'queclink';


export enum EHttpCommandTypes {
    RECOVERY_MODE = 1
}
