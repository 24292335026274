export interface IHolotrakItemFilter {
    search: string;
    filterType?: EDeviceFilterTypes;
    filter?: string;
}

export enum EDeviceFilterTypes {
    status = 'status',
    event = 'event',
    location = 'location',
}

export interface IAppliedFilter {
    [key: string]: string;
}
