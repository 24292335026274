import React, {createContext} from 'react';
import {EGroupType} from "../contracts/EGroupType";

interface IGroupedItemContext {
    groupType: EGroupType;
    currentGroup: string;
    setCurrentGroup: (group: string) => void;
}

interface IGroupedItemsProvider {
    groupType: EGroupType;
    children: React.ReactNode;
}

export const GroupedItemsContext = createContext<IGroupedItemContext>({
    groupType: EGroupType.DEVICE,
    currentGroup: null,
    setCurrentGroup: () => {
    },
});

// Provider Functional Component to ensure that the groupType is always set
export const GroupedItemsProvider: React.FC<IGroupedItemsProvider> = ({groupType, children}) => {
    const [currentGroup, setCurrentGroup] = React.useState<string>(null);
    return (
        <GroupedItemsContext.Provider value={{
            groupType,
            currentGroup,
            setCurrentGroup
        }}>
            {children}
        </GroupedItemsContext.Provider>
    );
}
