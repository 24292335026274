import {expressApi} from "./index";
import {TAG_INDOOR_ASSET} from "../../../contracts/reduxResourceTags";
import {IndoorAssetListResponse} from "../../../contracts/holotrak/deviceListResponse";
import {createEntityAdapter, createSelector, EntityState} from "@reduxjs/toolkit";
import {RootState} from "../../store";

import {MqttMessage} from "contracts/holotrak/mqttMessage";
import {selectIndoorAssetFilters} from "../../slices/app";
import {IndoorAsset, IndoorAssetSerializer} from "../../../contracts/holotrak/IndoorAsset";


export const indoorAssetsAdapter = createEntityAdapter<IndoorAsset>({
    selectId: (indoorAsset) => indoorAsset.id,
});

const initialState = indoorAssetsAdapter.getInitialState();

export const indoorAssetsApi = expressApi.injectEndpoints({
    endpoints: (builder) => ({
        // Indoor Assets List
        getIndoorAssets: builder.query<EntityState<IndoorAsset>, void>({
            query: () => `trackers`, // TODO: Change the Trackers endpoint to Indoor Assets
            providesTags: (result) => {
                if (result) {
                    return result.ids.map((id) => ({type: TAG_INDOOR_ASSET, id}));
                }
                return [{type: TAG_INDOOR_ASSET, id: 'LIST'}];
            },
            transformResponse: (response: IndoorAssetListResponse) => {
                return indoorAssetsAdapter.addMany(
                    initialState,
                    response.devices
                );
            },
            async onCacheEntryAdded(
                arg,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState}
            ) {
                let source: EventSource;
                const serverBase = process.env.REACT_APP_REALTIME_SERVER;
                const state = getState() as RootState;
                const organisationId = state.auth.user.experienceGroups[0].id;

                try {
                    await cacheDataLoaded;

                    /// TODO : Use single Universal Event Source for all trackers and Devices as well.
                    if (organisationId && 'EventSource' in window) {
                        const url = `${serverBase}/device/state?organizationId=${organisationId}`;
                        source = new EventSource(url);
                        const listener = (event: MessageEvent) => {
                            const data = JSON.parse(event.data);
                            // eslint-disable-next-line no-control-regex
                            const message = JSON.parse(data.message.replace(/[\u0000-\u0019]+/g, "-")) as MqttMessage;
                            updateCachedData((draft: EntityState<IndoorAsset>) => {
                                let indoorAsset = indoorAssetsAdapter.getSelectors().selectById(draft, message.deviceId);
                                const type = indoorAsset?.deviceClass;
                                indoorAsset = IndoorAssetSerializer.parse(indoorAsset);
                                if (indoorAsset && typeof indoorAsset.updateState === 'function') {
                                    indoorAsset.deviceClass = type;
                                    indoorAsset.updateState(message);
                                    indoorAssetsAdapter.upsertOne(draft, indoorAsset);
                                }
                            });
                        }
                        source.addEventListener('mqttMessage', listener, false);
                    }


                } catch {
                }
                await cacheEntryRemoved;
                if (source) {
                    source.close();
                }
            }
        }),
    })
});

export const {
    useGetIndoorAssetsQuery,
} = indoorAssetsApi;


export const selectIndoorAssetResult = indoorAssetsApi.endpoints.getIndoorAssets.select();

const selectIndoorAssetsData = createSelector(
    [selectIndoorAssetResult],
    (indoorAssetsResult) => indoorAssetsResult.data
);

export const {
    selectAll: selectAllIndoorAssetObjects,
    selectById: selectIndoorAssetById,
} = indoorAssetsAdapter.getSelectors(
    (state: RootState) => selectIndoorAssetsData(state) ?? initialState
);

export const selectAllIndoorAssets = createSelector(
    [selectAllIndoorAssetObjects],
    (indoorAssets) => indoorAssets.map(indoorAsset => IndoorAssetSerializer.parse(indoorAsset))
);


export const selectFilteredIndoorAssets = createSelector(
    [
        selectAllIndoorAssets,
        selectIndoorAssetFilters,
    ],
    (indoorAssets, filters) => {
        if (!filters?.search) {
            return indoorAssets.map(asset => IndoorAssetSerializer.parse(asset));
        }
        return indoorAssets.filter((asset) => asset.name.toLowerCase().includes(filters.search.toLowerCase())).map(asset => IndoorAssetSerializer.parse(asset)) || [];
    });
