import Icon from "@ant-design/icons";
import {CustomIconComponentProps} from "@ant-design/icons/es/components/Icon";
import React from "react";
import SVG from 'react-inlinesvg';
import image from '../svg/icon-graph.svg';

export const IconGraph = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => <SVG src={image}/>} {...props} />
);

export const SVGGraph = () => <SVG src={image}/>;
