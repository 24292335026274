import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { Trip, tripSerializer } from 'contracts/holotrak/trip';
import { tripsApi } from 'redux/services/losant/trips';
import { TRIPS_REDUCER_KEY } from 'contracts/reduxResourceTags';
import { DeviceEvent, deviceEventSerializer } from 'contracts/holotrak/deviceEvent';
import { RootState } from 'redux/store';


export const tripsAdapter = createEntityAdapter<Trip>({
    selectId: (trip) => trip.id,
});
export const tripsEventsAdapter = createEntityAdapter<DeviceEvent>({
    selectId: (event) => event.id,
});

const initialState = {
    trips: tripsAdapter.getInitialState(),
    tripsEvent: tripsEventsAdapter.getInitialState()
}

export const tripSlice = createSlice({
    name: TRIPS_REDUCER_KEY,
    initialState,
    reducers: {
        clearTrips: (state) => {
            tripsAdapter.removeAll(state.trips)
        }

    },
    extraReducers: (builder) => {

        builder.addMatcher(
            tripsApi.endpoints.getDeviceTrips.matchFulfilled,
            (state, { payload }) => {
                tripsAdapter.addMany(state.trips, payload)
            }
        );

        builder.addMatcher(
            tripsApi.endpoints.getDeviceEvents.matchFulfilled,
            (state, { payload }) => {
                tripsEventsAdapter.addMany(state.tripsEvent, payload)
            }
        );

    },
});

export default tripSlice.reducer;
export const {clearTrips } = tripSlice.actions;

export const {
    selectAll: getAllTripsFromAdapter,
} = tripsAdapter.getSelectors((state: RootState) => state.tripsCore.trips)

export const selectAlltrips = createSelector(
    getAllTripsFromAdapter,
    (trips) => {
        return trips.map(trip => tripSerializer.parse(trip)) || [];
    }
);

export const {
    selectAll: getAllEventTripsFromAdapter,
} = tripsEventsAdapter.getSelectors((state: RootState) => state.tripsCore.tripsEvent)

export const selectAllEventstrips = createSelector(
    getAllEventTripsFromAdapter,
    (tripsEvent) => {
        return tripsEvent.map((event) => deviceEventSerializer.parse(event));
    }
);