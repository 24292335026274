import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {AuthError, AuthResponse, LoginRequest} from "contracts/holotrak/authentication";
import {BugReport, IBugReportRequest} from "contracts/holotrak/BugReport";
import {
    LOSANT_API_REDUCER_KEY,
    TAG_ALERT,
    TAG_DEVICE,
    TAG_DEVICE_SENSOR,
    TAG_DEVICE_SETTINGS,
    TAG_DRIVER,
    TAG_EXPERIENCE_GROUP,
    TAG_GEOFENCE,
    TAG_GEOFENCE_SLOT_CONFIG,
    TAG_GROUP,
    TAG_HISTORICAL_REPORT,
    TAG_INDOOR_ASSET,
    TAG_INDOOR_LAYOUT,
    TAG_MESSAGE,
    TAG_NOTIFICATION,
    TAG_PRODUCT_CONFIGS,
    TAG_RECIPIENT,
    TAG_ROLE,
    TAG_USER,
    TAG_VEHICLE,
} from "contracts/reduxResourceTags";
import {RootState} from "redux/store";
import {ExperienceGroup} from "../../../contracts/holotrak/experienceGroup";
import {HolotrakUser} from "../../../contracts/holotrak/holotrakUser";
import {
    IForgetPasswordRequest,
    IForgetPasswordResponse,
    IResetPasswordRequest,
    IResetPasswordResponse
} from "../../../contracts/IForgetPassword";

export const losantApi = createApi({
    reducerPath: LOSANT_API_REDUCER_KEY,
    tagTypes: [
        TAG_DEVICE,
        TAG_DEVICE_SENSOR,
        TAG_PRODUCT_CONFIGS,
        TAG_VEHICLE,
        TAG_DRIVER,
        TAG_DEVICE_SETTINGS,
        TAG_EXPERIENCE_GROUP,
        TAG_GEOFENCE,
        TAG_GEOFENCE_SLOT_CONFIG,
        TAG_INDOOR_LAYOUT,
        TAG_NOTIFICATION,
        TAG_USER,
        TAG_ALERT,
        TAG_ROLE,
        TAG_RECIPIENT,
        TAG_GROUP,
        TAG_MESSAGE,
        TAG_INDOOR_ASSET,
        TAG_HISTORICAL_REPORT
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_SERVER,
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = (getState() as RootState).auth.accessToken;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        bugReport: builder.mutation<BugReport, IBugReportRequest>({
            query: (body) => {
                return {
                    url: "/bug-report",
                    method: "post",
                    body,
                }
            }
        }),
        loginUser: builder.mutation<AuthResponse | AuthError, LoginRequest>({
            query: (body) => {
                return {
                    url: "/auth/login",
                    method: "post",
                    body,
                };
            },
        }),
        loginRememberedUser: builder.mutation<AuthResponse | AuthError, LoginRequest>({
            query: (body) => {
                return {
                    url: "/auth/login",
                    method: "post",
                    body,
                };
            },
        }),
        getCurrentUser: builder.query<HolotrakUser, void>({
            query: () => "/current-user",
        }),
        getOrganisation: builder.query<ExperienceGroup, void>({
            providesTags: [TAG_EXPERIENCE_GROUP],
            query: () => "/organisation",
        }),
        updateOrganisation: builder.mutation<ExperienceGroup, ExperienceGroup>({
            invalidatesTags: [TAG_EXPERIENCE_GROUP],
            query: (organisation) => {
                return {
                    url: `/organisation/${organisation.id}`,
                    method: "put",
                    body: organisation.toJSON(),
                };
            }
        }),

        forgetPassword: builder.mutation<IForgetPasswordResponse, IForgetPasswordRequest>({
            query: (body) => {
                return {
                    url: "/auth/forget-password",
                    method: "post",
                    body,
                };
            }
        }),
        resetPassword: builder.mutation<IResetPasswordResponse, IResetPasswordRequest>({
            query: (body) => {
                return {
                    url: "/auth/reset-password",
                    method: "put",
                    body,
                };
            }
        }),
    }),
});

export const {
    useBugReportMutation,
    useLoginUserMutation,
    useGetCurrentUserQuery,
    useLoginRememberedUserMutation,
    useGetOrganisationQuery,
    useLazyGetOrganisationQuery,
    useUpdateOrganisationMutation,
    useForgetPasswordMutation,
    useResetPasswordMutation,
} = losantApi;


// RTK Query Selector for Organisation
export const selectOrganisation = (appState) => losantApi.endpoints.getOrganisation.select()(appState).data;
