import {losantApi} from "./index";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";
import {TAG_INDOOR_LAYOUT} from "../../../contracts/reduxResourceTags";
import {createSelector} from "@reduxjs/toolkit";
import {IndoorLayout, IndoorLayoutListResponse, indoorLayoutSerializer} from "../../../contracts/holotrak/indoorLayout";
import {selectAllIndoorAssets} from "../express/indoor-assets";
import {selectIndoorLayoutsList} from "../../slices/app";

const indoorLayoutsApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getIndoorLayoutList: builder.query<IndoorLayout[], ILosantListQuery>({
            providesTags: (result) => {
                return result ? [
                    ...result.map(fence => ({
                        type: TAG_INDOOR_LAYOUT,
                        id: fence.id
                    })), TAG_INDOOR_LAYOUT
                ] : [TAG_INDOOR_LAYOUT];
            },
            query: (queryArgs) => {
                return {
                    url: `/indoor-layout?searchValue=${queryArgs.searchValue}&fieldValue=${queryArgs.fieldValue}`,
                    method: "get",
                };
            },
            transformResponse: (response: IndoorLayoutListResponse) => {
                return response?.items;
            }
        }),
        deleteIndoorLayout: builder.mutation<void, string>({
            invalidatesTags: (result, error, layoutId) => {
                return [{type: TAG_INDOOR_LAYOUT, id: layoutId}];
            },
            query: (layoutId: string) => {
                return {
                    url: `/indoor-layout/${layoutId}`,
                    method: "delete",
                };
            }
        }),
        createIndoorLayout: builder.mutation<IndoorLayout, IndoorLayout>({
            invalidatesTags: (result, error, geofence) => {
                return [{type: TAG_INDOOR_LAYOUT, id: result?.id}, TAG_INDOOR_LAYOUT];
            },
            query: (layout: IndoorLayout) => {
                return {
                    url: `/indoor-layout`,
                    method: "post",
                    body: {data: layout},
                };
            }
        }),

        editIndoorLayout: builder.mutation<IndoorLayout, IndoorLayout>({
            invalidatesTags: (result, error, geofence) => {
                return [{type: TAG_INDOOR_LAYOUT, id: result?.id}, TAG_INDOOR_LAYOUT];
            },
            query: (indoorLayout: IndoorLayout) => {
                return {
                    url: `/indoor-layout/${indoorLayout.id}`,
                    method: "PUT",
                    body: {data: indoorLayout},
                };
            }
        }),
    })
});

export const {
    useGetIndoorLayoutListQuery,
    useCreateIndoorLayoutMutation,
    useEditIndoorLayoutMutation,
    useDeleteIndoorLayoutMutation,
} = indoorLayoutsApi;

export const selectIndoorLayouts = (appState, queryArgs) => indoorLayoutsApi.endpoints.getIndoorLayoutList.select(queryArgs)(appState);
export const selectIndoorLayoutsAndAssets = createSelector(
    [
        selectAllIndoorAssets,
        selectIndoorLayouts,
    ],
    (indoorAssets, layoutsList) => {
        return layoutsList.data?.map(layout => {
            const layoutObject = indoorLayoutSerializer.parse(layout);
            layoutObject.locatableItems = indoorAssets.filter(asset => (asset.regionId) ? layoutObject.id === asset.regionId : false);

            return layoutObject;
        }) ?? [];
    });

export const selectCurrentLayout = createSelector(
    [
        selectIndoorLayoutsList,
        selectAllIndoorAssets,
    ],
    (layoutsList, indoorAssets) => {
        if (layoutsList[0]) {
            const layout = indoorLayoutSerializer.parse(layoutsList[0]);

            layout.locatableItems = indoorAssets.filter(asset => (asset.regionId) ? layout.id === asset.regionId : false);

            return layout;
        }

        return null;
    }
);
