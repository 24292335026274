import React, {useEffect} from 'react';
import {IVehicleSaveRequest, Vehicle} from "../../contracts/holotrak/vehicle";
import {Button, Drawer, Form, message, Space} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import VehicleTabbedForm from "./VehicleTabbedForm";
import {useCreateVehicleMutation, useUpdateVehicleMutation} from "../../redux/services/losant/vehicles";
import DriversDropdown from "../dropdowns/DriversDropdown";
import DevicesDropdown from "../dropdowns/DevicesDropdown";

interface IVehicleFormProps {
    isOpen: boolean;
    onClose: () => void;
    vehicle: Vehicle;
}

const formFieldsToUse = [
    'id',
    'name',
    'device',
    'driver',
    'location',
    'make',
    'model',
    'year',
    'color',
    'vin',
    'stockNumber',
    'type',
    'subType',
    'insurancePolicyNumber',
    'insurancePolicyDate',
    'insurance2PolicyNumber',
    'insurance2PolicyDate',
    'tags',
    'notes',
    'engine',
    'horsePower',
    'transmission',
    'fuel',
    'fuelGrade',
    'fuelCapacity',
    'wheelDiameter',
    'wheelWidth',
    'tyreNumber',
    'tyreSize',
    'length',
    'width',
    'height',
    'weight',
    'passengerCapacity'
];

const VehicleForm: React.FC<IVehicleFormProps> = ({vehicle, onClose, isOpen}) => {
    const [vehicleForm] = Form.useForm<Partial<Vehicle>>();


    const [updateVehicle, {isLoading: isUpdatingVehicle}] = useUpdateVehicleMutation();
    const [createVehicle, {isLoading: isCreatingVehicle}] = useCreateVehicleMutation();


    const handleVehicleFormSubmit = () => {
        vehicleForm.validateFields().then(async values => {
            const formValuesToSend: IVehicleSaveRequest = {
                data: vehicleForm.getFieldsValue(formFieldsToUse)
            };

            if (formValuesToSend.data.id) {
                updateVehicle(formValuesToSend).then(() => {
                    message.success("Vehicle updated successfully.");
                });
            } else {
                createVehicle(formValuesToSend).then(() => {
                    onClose();
                });
            }
        });
    }


    useEffect(() => {
        vehicleForm.resetFields();
        vehicleForm.setFieldsValue(vehicle);

    }, [vehicle, vehicleForm]);

    return (
        <Drawer
            title={`Vehicle`}
            closable={false}
            onClose={onClose}
            size={'large'}
            open={isOpen}
            extra={
                <Space>
                    <Button
                        htmlType="submit"
                        className="grey-btn"
                        onClick={handleVehicleFormSubmit}
                        loading={isUpdatingVehicle || isCreatingVehicle}
                    >
                        {vehicle?.id ? 'Update' : 'Create'}
                    </Button>
                    <Button
                        onClick={onClose}
                        type="link"
                        icon={<CloseOutlined/>}
                    />
                </Space>
            }
        >
            <Form
                name='vehicle-form'
                form={vehicleForm}
                initialValues={{
                    ...vehicle
                }}
                onFinish={handleVehicleFormSubmit}
            >

                <Form.Item label={"Select Driver"} shouldUpdate>
                    {
                        () => {
                            return (
                                <DriversDropdown fieldName={['driver', 'id']}/>
                            );
                        }
                    }
                </Form.Item>
                <Form.Item label={"Select Device"} shouldUpdate>
                    {
                        (form) => (
                            <DevicesDropdown
                                defaultValue={form.getFieldValue(["device", 'id'])}
                                onSelect={(value: string) => {
                                    form.setFieldValue(["device", 'id'], value)
                                }}
                                onClear={() => {
                                    form.setFieldValue(["device", 'id'], "")
                                }}
                            />
                        )
                    }
                </Form.Item>

                <Form.Item
                    dependencies={formFieldsToUse}
                >
                    {
                        (form) => <VehicleTabbedForm/>
                    }
                </Form.Item>
            </Form>
        </Drawer>
    );
}


export default VehicleForm;
