import React, { useEffect } from "react";
import { expressApi } from "redux/services/express";
import { losantApi } from "redux/services/losant";
import { useCreateActivityLogMutation } from "redux/services/losant/activeLog";
import { logout, selectCurrentUser } from "redux/slices/auth";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { useGeolocationInfo } from "utils/useGeolocationInfo";


export const Logout: React.FC = () => {
    const [createActivityLog] = useCreateActivityLogMutation();
    const userIpInfo = useGeolocationInfo();
    const user = useTypedSelector(selectCurrentUser);
    const appDispatch = useAppDispatch();
    useEffect(() => {
        appDispatch(expressApi.util.resetApiState());
        appDispatch(losantApi.util.resetApiState());

        createActivityLog({
            ...userIpInfo,
            userName: `${user?.firstName || ""} ${user?.lastName || ""}`.trim() || "",
            user: user?.id || "",
            organisation: user?.userTags?.organizationId || "",
            actionType: 'sessionEnd'
        }).then(() => {
            appDispatch(logout());
            // Reload the page to reset the state
            window.location.reload();
        });


    }, []);

    return null;
}

export default Logout;
