import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {ExperienceGroup} from "./experienceGroup";
import {UserRole} from "./userRole";
import {UserTags} from "./userTags";

@jsonObject
export class HolotrakUser {
    @jsonMember(String)
    firstName: string;

    @jsonMember(String)
    lastName: string;

    @jsonMember(String)
    email: string;

    @jsonMember(String)
    id: string;

    @jsonMember(String)
    avatarUrl: string;

    @jsonMember(String)
    applicationId: string;

    @jsonMember(String)
    creationDate: string;

    @jsonMember(String)
    lastLogin: string;

    @jsonMember(String)
    experienceUserId: string;

    @jsonMember(String)
    lastUpdated: string;

    @jsonMember(UserTags)
    userTags: UserTags;

    @jsonArrayMember(ExperienceGroup)
    experienceGroups: ExperienceGroup[];

    @jsonArrayMember(UserRole)
    roles: UserRole[];


    get fullName(): string {
        return `${this.firstName} ${!!this.lastName ? this.lastName : ''}`.trim();
    }

    get avatar(): string {
        return this.avatarUrl;
    }
}

export const HolotrakUserSerializer = new TypedJSON(HolotrakUser);
