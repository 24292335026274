import {jsonMember, jsonObject, TypedJSON} from "typedjson";

@jsonObject()
export class TripData {
    @jsonMember(String)
    location: string;

    @jsonMember(Number, {
        deserializer: (value: any) => {
            return value && parseFloat(value).toFixed(2);
        }
    })
    speed?: number;

    @jsonMember(String)
    address?: string;

    get latitude(): number {
        return parseFloat(this.location.split(",")[0]);
    }

    get longitude(): number {
        return parseFloat(this.location.split(",")[1]);
    }
}

export const tripDataSerializer = new TypedJSON(TripData);
