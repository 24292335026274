import { Layout, Menu } from "antd";
import iconArrowLeft from "assets/svg/icon-arrow-left.svg";
import logoCollapsed from "assets/svg/logo-collapsed.svg";

import logo from "assets/svg/logo-white.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./sider.scss";
import { IconVoltage } from "../../assets/icons/icon-voltage";
import { IconVehicle } from "../../assets/icons/icon-vehicle";
import { IconGraph } from "../../assets/icons/icon-graph";
import { IconGridFilled } from "../../assets/icons/icon-grid-filled";
import { IconBell } from "../../assets/icons/icon-bell";
import { IconMessage } from "../../assets/icons/icon-message";
import { IconHelp } from "../../assets/icons/icon-help";
import { IconLock } from "../../assets/icons/icon-lock";
import { IconSetting } from "../../assets/icons/icon-setting";
import { IconUser } from "../../assets/icons/icon-user";
import { EGroupType, getGroupTypeName, getRouteForGroupType } from "../../contracts/EGroupType";
import { UserProfile } from "./UserProfile";
import { EResource } from "../../contracts/EResource";
import { roleHasPermissionForResource } from "../../utils/useUserPermissionsForRole";
import { useTypedSelector } from "../../redux/store";
import { selectCurrentUser } from "../../redux/slices/auth";
import { EPermission } from "../../contracts/EPermission";
import { IconMaintenance } from "assets/icons/icon-settings";

const { Sider } = Layout;

export function HolotrakSider() {
    const currentLocation = useLocation();
    const navigate = useNavigate();

    const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
    const [currentRoute, setCurrentRoute] = useState("dashboard");

    const { roles = [] } = useTypedSelector(selectCurrentUser) ?? {};

    useEffect(() => {
        let [, route] = currentLocation.pathname.split("/");

        if (!route) {
            route = "dashboard";
        }

        setCurrentRoute(route);
    }, [currentLocation.pathname]);

    const onMenuClick = useCallback(
        (e) => {
            switch (e.key) {
                case "dashboard":
                    navigate("/");
                    break;

                case "reports":
                    navigate("/report/historical");
                    break;

                case "settings":
                    navigate("/settings/general");
                    break;

                default:
                    navigate(`/${e.key}`);
                    break;
            }
        },
        [navigate]
    );

    const checkPermission = useCallback(
        (resource: EResource, permission: EPermission) => {
            return roleHasPermissionForResource(resource, permission, roles);
        },
        [roles]
    );

    const menu = useMemo(() => {
        let primaryMenuItems = [
            {
                key: "dashboard",
                label: "Dashboard",
                icon: <IconGridFilled />,
            },
            {
                resource: EResource.DEVICES,
                key: getRouteForGroupType(EGroupType.DEVICE),
                label: getGroupTypeName(EGroupType.DEVICE),
                icon: <IconVoltage />,
            },
            {
                resource: EResource.DEVICE_SENSORS,
                key: getRouteForGroupType(EGroupType.DEVICE_SENSOR),
                label: getGroupTypeName(EGroupType.DEVICE_SENSOR),
                icon: <IconVoltage />,
            },
            {
                resource: EResource.VEHICLES,
                key: getRouteForGroupType(EGroupType.VEHICLE),
                label: getGroupTypeName(EGroupType.VEHICLE),
                icon: <IconVehicle />,
            },
            {
                resource: EResource.INDOOR_ASSETS,
                key: getRouteForGroupType(EGroupType.INDOOR_ASSET),
                label: getGroupTypeName(EGroupType.INDOOR_ASSET),
                icon: <IconVehicle />,
            },
            {
                resource: EResource.INDOOR_ASSETS,
                key: getRouteForGroupType(EGroupType.INDOOR_POSITION),
                label: getGroupTypeName(EGroupType.INDOOR_POSITION),
                icon: <IconVehicle />,
            },
            {
                resource: EResource.DRIVERS,
                key: getRouteForGroupType(EGroupType.DRIVER),
                label: getGroupTypeName(EGroupType.DRIVER),
                icon: <IconUser />,
            },
            {
                resource: EResource.REPORTS,
                key: getRouteForGroupType(EGroupType.REPORT),
                label: getGroupTypeName(EGroupType.REPORT),
                icon: <IconGraph />,
            },
        ].filter((menuItem) => {
            return !menuItem.resource ? true : checkPermission(menuItem.resource, EPermission.READ);
        });

        let secondaryMenuItems = [
            {
                key: "maintenance",
                label: "Maintenance",
                icon: <IconMaintenance />,
            },
            {
                resource: EResource.NOTIFICATIONS,
                key: "notifications",
                label: "Notifications",
                icon: <IconBell />,
            },
            {
                resource: EResource.MESSAGES,
                key: "messages",
                label: "Messages",
                icon: <IconMessage />,
            },
            {
                key: "help",
                label: "Help",
                icon: <IconHelp />,
            },
            {
                key: "settings",
                label: "Settings",
                icon: <IconSetting />,
            },
            {
                key: "logout",
                label: "Logout",
                icon: <IconLock />,
            },
        ].filter((menuItem) => {
            return !menuItem.resource ? true : checkPermission(menuItem.resource, EPermission.READ);
        });

        return {
            primary: primaryMenuItems,
            secondary: secondaryMenuItems,
        };
    }, [checkPermission]);

    return (
        <Sider
            width={140}
            collapsible
            className={"holotrak-sider"}
            theme={"light"}
            collapsed={isSiderCollapsed}
            onCollapse={(value) => setIsSiderCollapsed(value)}
            trigger={
                isSiderCollapsed ? (
                    <img src={logoCollapsed} alt="Holotrak Logo (H)" />
                ) : (
                    <img src={logo} alt="Holotrak Logo" />
                )
            }
        >
            <span
                className={isSiderCollapsed ? "sider-collapse-icon" : "sider-collapse-icon"}
                onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
            >
                <img src={iconArrowLeft} alt="" />
            </span>

            <UserProfile isCollapsed={isSiderCollapsed} />

            <Menu
                selectedKeys={[currentRoute]}
                onClick={onMenuClick}
                className={"holotrak-menu--primary"}
                items={menu.primary}
            />

            <Menu
                selectedKeys={[currentRoute]}
                onClick={onMenuClick}
                className={"holotrak-menu--secondary"}
                items={menu.secondary}
            />
        </Sider>
    );
}

