import {createSelector} from "@reduxjs/toolkit";
import {losantApi} from "./index";
import {
    DeviceSubscription,
    DeviceSubscriptionListResponse,
    IDeviceSubscriptionQuery
} from "contracts/holotrak/deviceSubscriptionListResponse";

const subscriptionsApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceSubscriptions: builder.query<DeviceSubscription[], IDeviceSubscriptionQuery>({
            query: (queryArg: IDeviceSubscriptionQuery) => {
                return {
                    url: "/subscriptions",
                    method: "get",
                };
            },
            merge(existing, incoming) {
                return [...existing, ...incoming];
            },
            transformResponse: (response: DeviceSubscriptionListResponse) => {
                return response.subscriptions;
            },
        }),
    })
});


export const {
    useGetDeviceSubscriptionsQuery,
} = subscriptionsApi;


export const selectDeviceSubscriptionResult = (appState, queryArgs) =>
    subscriptionsApi.endpoints.getDeviceSubscriptions.select(queryArgs)(appState);

export const selectDeviceSubscriptionEntities = createSelector(
    [
        selectDeviceSubscriptionResult,
        (appState, queryArgs) => {
            return queryArgs;
        },
    ],
    (selectedData, queryArgs) => {
        return selectedData.data || [];
    }
);
