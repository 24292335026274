import React, {useMemo} from 'react';
import {Button, Card, Col, Collapse, Input, Layout, Row, Space, Spin, Typography} from 'antd';
import Helmet from 'react-helmet';

import {IconHelpAlternate} from "../../assets/icons/icon-help-alternate";
import {IconSchedule} from "../../assets/icons/icon-schedule";
import {IconQuestion} from "../../assets/icons/icon-question";
import {IconCall} from '../../assets/icons/icon-call';
import useTablePagination from "../../utils/useTablePagination";


import './help.scss';
import {useGetFrequentQuestionsQuery} from "../../redux/services/losant/settings";
import {FrequentlyAskedQuestion} from "../../contracts/holotrak/frequentQuestion";

const {Panel} = Collapse;

interface IHelpPageProps {

}

interface SupportButton {
    text: string;
    link: string;
    icon: React.ReactNode;
}


export const HelpPage: React.FC<IHelpPageProps> = (props) => {
    const {searchTerm, handleSearch} = useTablePagination();

    const {isLoading, data} = useGetFrequentQuestionsQuery();

    const {
        title,
        contact,
    } = useMemo(() => ({
        title: 'Help Center',
        contact: {
            label: "+1 800 675 9087",
            link: "tel:+18006759087",
        },
    }), []);

    const faqs = useMemo<FrequentlyAskedQuestion[]>(() => {
        if (!data || !data?.items) return [];

        return searchTerm
            ? data
                ?.items
                .filter((faq: FrequentlyAskedQuestion) =>
                    (faq.question.toLowerCase().includes(searchTerm.toLowerCase()) || faq.answer.toLowerCase().includes(searchTerm.toLowerCase()))
                )
            : data?.items;
    }, [searchTerm, data]);


    const supportButtons = useMemo<SupportButton[]>(() =>
        [
            {
                link: `https://calendly.com/danholotrak_2022`,
                text: `Schedule Demo`,
                icon: <IconSchedule/>
            },
            {
                link: '#',
                text: `FAQ`,
                icon: <IconQuestion/>
            },
            {
                text: "Get Support",
                link: `mailto:support@holotrak.com?subject=The%20subject%20of%20the%20mail%20Support`,
                icon: <IconHelpAlternate/>
            },
        ], []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <Layout className='holotrak-page--help'>
                <Layout.Header
                    className='holotrak-help--header'
                >
                    <Space>
                        <Typography.Title level={1}>
                            {title}
                        </Typography.Title>


                        <Space
                            className="holotrak-search--container"
                        >
                            <Typography.Paragraph className={'holotrak-call-support'}>
                                <IconCall/>
                                <Typography.Link href={contact.link} target="_blank">
                                    Call : {contact.label}
                                </Typography.Link>
                            </Typography.Paragraph>

                            <Input.Search
                                className="holotrak-search--input"
                                placeholder="Ask a Question"
                                onSearch={handleSearch}
                                defaultValue={searchTerm}
                                allowClear
                            />
                        </Space>


                    </Space>
                </Layout.Header>

                <Layout.Content>
                    <Spin spinning={isLoading}>
                        <Row>
                            <Col span={24}>
                                <Typography.Title level={2}>
                                    How Can we Help You?
                                </Typography.Title>
                            </Col>

                            <Col span={24}>
                                <Row gutter={20}>
                                    {
                                        supportButtons.map((button: SupportButton, index: number) => (
                                            <Col key={index} span={8}>
                                                <Card className={'holotrak-card--support'}>
                                                    <Button
                                                        className='holotrak-button--support'
                                                        href={button.link}
                                                        icon={button.icon}
                                                        title={button.text}
                                                        target={'_blank'}
                                                        ghost
                                                    >
                                                        <Typography.Text>
                                                            {button.text}
                                                        </Typography.Text>
                                                    </Button>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Row>


                                <Row className={'holotrak-faq--support'}>
                                    <Col span={24}>
                                        <Typography.Title level={3}>
                                            Frequently Asked Questions
                                        </Typography.Title>
                                    </Col>

                                    <Col span={24}>
                                        <Collapse defaultActiveKey={[]} accordion>
                                            {
                                                faqs.map((faq, index) => (
                                                    <Panel header={faq.question} key={index}>
                                                        <Typography.Paragraph>
                                                            {faq.answer}
                                                        </Typography.Paragraph>
                                                    </Panel>
                                                ))
                                            }
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </Layout.Content>
            </Layout>
        </>
    );
};


export default HelpPage;
