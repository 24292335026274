export enum ETemperatureUnit {
    Celsius = 'C',
    Fahrenheit = 'F',
    Kelvin = 'K',
}

export function convertStringToTemperatureUnit(value: string): ETemperatureUnit {
    switch (`${value}`.toLowerCase()) {
        case 'c':
        case 'celsius':
            return ETemperatureUnit.Celsius;
        case 'f':
        case 'fahrenheit':
            return ETemperatureUnit.Fahrenheit;
        case 'k':
        case 'kelvin':
            return ETemperatureUnit.Kelvin;
        default:
            return ETemperatureUnit.Fahrenheit;
    }
}


export function convertTemperature(value: string|number, inputUnit: ETemperatureUnit, outputUnit: ETemperatureUnit): number {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    if (inputUnit === outputUnit) {
        return value;
    }

    switch (inputUnit) {
        case ETemperatureUnit.Celsius:
            return convertCelsiusTo(value, outputUnit);
        case ETemperatureUnit.Fahrenheit:
            return convertFahrenheitTo(value, outputUnit);
        case ETemperatureUnit.Kelvin:
            return convertKelvinTo(value, outputUnit);
    }
}

function convertCelsiusTo(value: number, outputUnit: ETemperatureUnit): number {
    switch (outputUnit) {
        case ETemperatureUnit.Fahrenheit:
            return (value * 9 / 5) + 32;
        case ETemperatureUnit.Kelvin:
            return value + 273.15;
        default:
            return value;
    }
}

function convertFahrenheitTo(value: number, outputUnit: ETemperatureUnit): number {
    switch (outputUnit) {
        case ETemperatureUnit.Celsius:
            return (value - 32) * 5 / 9;
        case ETemperatureUnit.Kelvin:
            return (value + 459.67) * 5 / 9;
        default:
            return value;
    }
}

function convertKelvinTo(value: number, outputUnit: ETemperatureUnit): number {
    switch (outputUnit) {
        case ETemperatureUnit.Celsius:
            return value - 273.15;
        case ETemperatureUnit.Fahrenheit:
            return (value * 9 / 5) - 459.67;
        default:
            return value;
    }
}
