import {Col, Drawer, FloatButton, Row, Spin} from "antd";
import React, {useCallback} from "react";
import {Helmet} from "react-helmet";
import {Route, Routes} from "react-router-dom";
import {useAppDispatch, useTypedSelector} from "redux/store";
import {selectLeftDrawerOpen, toggleLeftDrawer} from "../../redux/slices/app";
import {IconArrowRightCircle} from "../../assets/icons/icon-arrow-right-circle";
import {selectFilteredIndoorAssets, useGetIndoorAssetsQuery} from "../../redux/services/express/indoor-assets";
import {IndoorBottomBar} from "../../components/bottom-bar/IndoorBottomBar";

const IndoorAssetsList = React.lazy(() => import("./list"));
const IndoorAssetDetails = React.lazy(() => import("components/indoor-asset-details"));
const IndoorPane = React.lazy(() => import("components/indoor-pane/three"));
const IndoorDrawer = React.lazy(() => import("components/indoor-layout-management/drawer/IndoorDrawer"));

export const IndoorAssets: React.FC = () => {
    const dispatch = useAppDispatch();
    // Using a query hook automatically fetches data and returns query values
    const {isLoading: isLoadingIndoorAssets, error: errorInLoadingIndoorAssets} = useGetIndoorAssetsQuery();
    const indoorAssets = useTypedSelector(selectFilteredIndoorAssets);

    const isLeftDrawerOpen = useTypedSelector(selectLeftDrawerOpen);
    const toggleDrawer = useCallback(() => {
        dispatch(toggleLeftDrawer());
    }, [dispatch]);


    const [bottomBarHeight, setBottomBarHeight] = React.useState(0);
    const bottomBarRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (bottomBarRef.current) {
            setBottomBarHeight(bottomBarRef.current.clientHeight);
        }
    }, [bottomBarRef]);


    return (
        <>
            <Helmet>
                <title>Indoor Assets</title>
                <meta name="description" content="Holotrak Managed Indoor Assets"/>
            </Helmet>
            <Row>
                <Col span={24}>
                    <FloatButton
                        className="left-drawer-toggle"
                        type='default'
                        shape="square"
                        onClick={toggleDrawer}
                        icon={<IconArrowRightCircle/>}
                    />
                    <Drawer
                        placement="left"
                        mask={false}
                        open={isLeftDrawerOpen}
                        closable={false}
                        getContainer={false}
                        motion={null}
                        bodyStyle={{padding: 0}}
                    >
                        <React.Suspense fallback={<Spin spinning={true}/>}>
                            <Routes>
                                <Route
                                    path="/"
                                    index
                                    element={
                                        <IndoorAssetsList
                                            error={errorInLoadingIndoorAssets}
                                            isLoading={isLoadingIndoorAssets}
                                            items={indoorAssets}
                                        />
                                    }
                                />
                                <Route path="/:id" element={<IndoorAssetDetails/>}/>
                            </Routes>
                        </React.Suspense>
                    </Drawer>

                    <React.Suspense fallback={<Spin spinning={true}/>}>
                        <IndoorPane
                            consumedHeight={bottomBarHeight}
                        />
                        <IndoorDrawer/>
                    </React.Suspense>

                    <Row ref={bottomBarRef}>
                        <Col span={24}>
                            <IndoorBottomBar/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default IndoorAssets;
