import {losantApi} from "./index";
import {Geofence, GeofenceListResponse, geofenceSerializer} from "../../../contracts/holotrak/geofence";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";
import {TAG_GEOFENCE} from "../../../contracts/reduxResourceTags";
import {createSelector} from "@reduxjs/toolkit";
import {selectGeoFenceBounds} from "../../slices/app";
import {DeviceBaseSerializer} from "../../../contracts/holotrak/deviceBase";
import {selectAllDevices} from "../express";

const geofenceApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getGeofenceList: builder.query<Geofence[], ILosantListQuery>({
            providesTags: (result) => {
                return [
                    ...result.map(fence => ({
                        type: TAG_GEOFENCE,
                        id: fence.id
                    })), TAG_GEOFENCE
                ];
            },
            query: (queryArgs) => {
                return {
                    url: `/geofence?searchValue=${queryArgs.searchValue}&fieldValue=${queryArgs.fieldValue}`,
                    method: "get",
                };
            },
            transformResponse: (response: GeofenceListResponse) => {
                return response?.items;
            }
        }),
        deleteGeofence: builder.mutation<void, string>({
            invalidatesTags: (result, error, geofenceId) => {
                return [{type: TAG_GEOFENCE, id: geofenceId}];
            },
            query: (geofenceId: string) => {
                return {
                    url: `/geofence/${geofenceId}`,
                    method: "delete",
                };
            }
        }),
        createGeofence: builder.mutation<Geofence, Geofence>({
            invalidatesTags: (result, error, geofence) => {
                return [{type: TAG_GEOFENCE, id: result?.id}, TAG_GEOFENCE];
            },
            query: (geofence: Geofence) => {
                return {
                    url: `/geofence`,
                    method: "post",
                    body: {data: geofence},
                };
            }
        }),

        editGeofence: builder.mutation<Geofence, Geofence>({
            invalidatesTags: (result, error, geofence) => {
                return [{type: TAG_GEOFENCE, id: result?.id}, TAG_GEOFENCE];
            },
            query: (geofence: Geofence) => {
                return {
                    url: `/geofence/${geofence.id}`,
                    method: "PUT",
                    body: {data: geofence},
                };
            }
        }),
    })
});

export const {
    useGetGeofenceListQuery,
    useCreateGeofenceMutation,
    useEditGeofenceMutation,
    useDeleteGeofenceMutation,
} = geofenceApi;

export const selectGeoFenceResult = (appState, queryArgs) => geofenceApi.endpoints.getGeofenceList.select(queryArgs)(appState);
export const selectGeofenceWithDevices = createSelector(
    [
        selectAllDevices,
        selectGeoFenceResult,
        selectGeoFenceBounds
    ],
    (devices, geofenceList, bounds) => {
        return geofenceList.data?.map(geofence => {
            const geoObject = geofenceSerializer.parse(geofence);
            geoObject.locatableItems = devices.filter(device => {
                if (bounds[geofence.id]) {
                    const deviceObject = DeviceBaseSerializer.parse(device);
                    return (deviceObject.latLng) ? bounds[geofence.id].contains(deviceObject.latLng) : false;
                }
                return false;
            });

            return geoObject;
        }) ?? [];
    });
