export interface VehicleType {
    value: string;
    label: string;

    subTypes?: VehicleType[];
}

export const VEHICLE_TYPES: VehicleType[] = [
    {
        value: "car",
        label: "Car",
        subTypes: [
            {
                value: "sedan",
                label: "Sedan",
            },
            {
                value: "hatchback",
                label: "Hatchback",
            },
            {
                value: "coupe",
                label: "Coupe",
            },
            {
                value: "suv",
                label: "SUV",
            },
            {
                label: 'Cargo Van',
                value: 'cargoVan',
            },
            {
                label: 'Convertible',
                value: 'convertible',
            },
            {
                label: 'Crew Cap Pickup',
                value: 'crewCapPickup',
            },
            {
                label: 'Extended Cab Pickup',
                value: 'extendedCabPickup',
            },
            {
                label: 'Minivan',
                value: 'minivan',
            },
            {
                label: 'Passenger Van',
                value: 'passengerVan',
            },
            {
                label: 'Regular Cab Pickup',
                value: 'regularCabPickup',
            },
            {
                label: 'Wagon',
                value: 'wagon',
            },
        ]
    },
    {
        value: "truck",
        label: "Truck",
        subTypes: [
            {
                label: 'Box Vans',
                value: 'boxVans',
            },
            {
                label: 'Cab & Chassis',
                value: 'cab&Chassis',
            },
            {
                label: 'Car Carrier Truck',
                value: 'carCarrierTruck',
            },
            {
                label: 'Dump Truck',
                value: 'dumpTruck',
            },
            {
                label: 'Emergency Vehicle',
                value: 'emergencyVehicle',
            },
            {
                label: 'Expeditor Truck',
                value: 'expeditorTruck',
            },
            {
                label: 'Light Duty Truck',
                value: 'lightDutyTruck',
            },
            {
                label: 'Mixer',
                value: 'mixer',
            },
            {
                label: 'Plow Truck',
                value: 'plowTruck',
            },
            {
                label: 'Refuse Truck',
                value: 'refuseTruck',
            },
            {
                label: 'Step Van',
                value: 'stepVan',
            },
            {
                label: 'Tractor',
                value: 'tractor',
            },
            {
                label: 'Vocational Truck',
                value: 'vocationalTruck',
            },
            {
                label: 'Wrecker',
                value: 'wrecker',
            },
            {
                label: 'Yard Spotter',
                value: 'yardSpotter',
            },
        ]
    },
    {
        value: "trailer",
        label: "Trailer",
        subTypes: [
            {
                label: 'Agricultural/Livestock',
                value: 'agricultural/livestock',
            },
            {
                label: 'Car Carrier Trailer',
                value: 'carCarrierTrailer',
            },
            {
                label: 'Dry Van Trailer',
                value: 'dryVanTrailer',
            },
            {
                label: 'Dump Trailer',
                value: 'dumpTrailer',
            },
            {
                label: 'Flatbed Trailer',
                value: 'flatbedTrailer',
            },
            {
                label: 'GooseNeck Trailer',
                value: 'gooseNeckTrailer',
            },
            {
                label: 'Lowboy Trailer',
                value: 'lowboyTrailer',
            },
            {
                label: 'Reefer Trailer',
                value: 'reeferTrailer',
            },
            {
                label: 'Tag Trailer',
                value: 'tagTrailer',
            },
            {
                label: 'Tank Trailer',
                value: 'tankTrailer',
            },
            {
                label: 'Truck & Trailer Body',
                value: 'truck&TrailerBody',
            },
        ]
    },
    {
        value: "bus",
        label: "Bus",
        subTypes: [
            {
                label: 'Activity Bus',
                value: 'activityBus',
            },
            {
                label: 'ADA Bus',
                value: 'adaBus',
            },
            {
                label: 'Commercial Bus',
                value: 'commercialBus',
            },
            {
                label: 'Executive Coach',
                value: 'executiveCoach',
            },
            {
                label: 'Low Floor',
                value: 'lowFloor',
            },
            {
                label: 'Mini ScooBis',
                value: 'miniScoobis',
            },
            {
                label: 'Passenger Van',
                value: 'passengerVan',
            },
            {
                label: 'School Bus',
                value: 'schoolBus',
            },
            {
                label: 'Shuttle Bus',
                value: 'shuttleBus',
            },
            {
                label: 'Wheel Chair Van',
                value: 'wheelChairVan',
            },
        ]
    }
];


export function getVehicleTypeByValue(value: string): VehicleType | undefined {
    return VEHICLE_TYPES.find((type) => type.value === value);
}
