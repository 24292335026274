import {useCallback, useState} from "react";


const useToggleValue = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue);
    const toggleValue = useCallback(() => setValue((prev) => !prev), []);

    return [value, toggleValue, setValue] as const;
}

export default useToggleValue;
