import {useCallback, useState} from 'react';

export interface Sorting {
    field: string;
    order: 'ascend' | 'descend';
}


const useTablePagination = (initial = {}, sortConfig = {}) => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 18,
        ...initial,
    });

    const [sorting, setSorting] = useState<Sorting>({
        field: 'name',
        order: 'ascend',
        ...sortConfig,
    });

    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleTableChange = useCallback((pagination, filter, sorter) => {
        if (sorter.field) {
            setSorting({
                field: sorter.field,
                order: sorter.order,
            });
        }

        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize,
        });
    }, []);

    const handleSearch = useCallback((value) => {
        setSearchTerm(value);
        handleTableChange({
            current: 1,
            pageSize: pagination.pageSize,
        }, {}, {
            field: 'name',
            order: 'asc',
        });
    }, [handleTableChange, pagination.pageSize]);

    return {
        pagination,
        sorting,
        setPagination,
        searchTerm,
        setSearchTerm,
        handleTableChange,
        handleSearch
    };
};

export default useTablePagination;
