import dayjs from "dayjs";
import { LatLng } from "leaflet";
import { jsonMember, jsonObject, TypedJSON } from "typedjson";
import { EDeviceCommandTypes, OutputSupportedDeviceType } from "../EDeviceCommandTypes";
import { EDeviceStatus } from "../EDeviceStatus";
import { ESubscriptionStatus } from "../ESubscriptionStatus";
import { IMappable } from "../IMappable";
import { IOutdoorLocatable } from "../IOutdoorLocatable";
import { DeviceSubscription } from "./deviceSubscriptionListResponse";
import { LosantCoreDevice } from "./losantCoreDevice";
import { Plan } from "./plan";


@jsonObject()
export class DeviceBase extends LosantCoreDevice implements IOutdoorLocatable, IMappable {

    @jsonMember(Plan)
    plan: Plan;
    @jsonMember(DeviceSubscription)
    subscription: DeviceSubscription;

    // also treated as IMEI number
    get mobileId(): string {
        return this.getTagValue('mobileId');
    }


    // mainly for Sensors
    get sensorModel(): string {
        return this.getTagValue('sensorModel');
    }

    get subscriptionStatus(): ESubscriptionStatus {
        if (!this.subscription) {
            return ESubscriptionStatus.INACTIVE;
        }

        if (this.subscription?.isDeleted) {
            return ESubscriptionStatus.RETIRED;
        }

        if (this.subscription?.isActive) {
            return ESubscriptionStatus.ACTIVE;
        } else {
            return ESubscriptionStatus.DEACTIVATED;
        }
    }


    get activationDate(): dayjs.Dayjs | null {
        return this.subscription?.createdAt;
    }

    get expiryDate(): dayjs.Dayjs | null {
        return this.subscription?.expiringAt;
    }

    get deviceName(): string {
        return this.name;
    }

    get latLng(): null | LatLng {
        const locationTuple = this.getCompositeValue('location', '', null, false)?.split(',') || [];
        if (locationTuple.length === 2) {
            return new LatLng(Number(locationTuple[0]), Number(locationTuple[1]));
        }

        return null;
    }

    get isMoving(): boolean {
        return this.status === EDeviceStatus.ONLINE;
    };

    get isOffline(): boolean {
        return this.status === EDeviceStatus.OFFLINE;
    }

    static getOutputCommand(deviceType: OutputSupportedDeviceType, outputNumber: number, isOn: boolean, deviceModel?: string): string | void {

        switch (`${deviceType}-${outputNumber}`.toLowerCase()) {
            case 'atrack-0':
                return (isOn) ? 'AT$OUTC=1,1' : 'AT$OUTC=1,0';

            case 'atrack-1':
                return (isOn) ? 'AT$OUTC=2,1' : 'AT$OUTC=2,0';

            case 'calamp-0':
                return (isOn) ? '!R3,8,0' : '!R3,9,0';

            case 'calamp-1':
                return (isOn) ? '!R3,8,1' : '!R3,9,1';

            case 'yabby edge-2':
                return (isOn) ? '3' : '1';
                
            case 'queclink-1':
                if(deviceModel === 'queclink-gv-620-mg') {
                    return (isOn) ? 'AT+GTCFG=gv620mg,,GV600M,0,0,,,007D,2,,002C,3,0,0,300,00,,1,0,001F,3,1,1,,,,12,10,15,0,0,0,FFFF$' : undefined
                }
                if (deviceModel === 'queclink-gv-57-mg') {
                    return (isOn) ? 'AT+GTCFG=gv57,,GV57MGV2,0,0,,,007D,2,,002C,3,0,0,300,00,,1,0,001F,3,1,1,,,,12,10,15,0,0,0,FFFF$' : undefined
                }
        }
        return undefined;
    }

    static getLocateCommand(deviceType: OutputSupportedDeviceType, deviceModel: string, type: string): string | void {
        switch (`${deviceType}`.toLowerCase()) {
            case 'queclink':
                // For 'queclink-gv-620-mg' model
                if (deviceModel === 'queclink-gv-620-mg') {
                    switch (type) {
                        case 'arm':
                            return 'AT+GTOUT=gv620mg,0,0,0,1,0,0,0,0,0,0,0,0,2,0,120,0,0,FFFF$';
                        case 'disarm':
                            return 'AT+GTOUT=gv620mg,0,0,0,0,0,0,0,0,0,0,0,0,2,0,120,0,0,FFFF$';
                    }
                }
                // For 'queclink-gv-57-mg' model
                if (deviceModel === 'queclink-gv-57-mg') {
                    switch (type) {
                        case 'arm':
                            return 'AT+GTOUT=gv57,0,0,0,1,0,0,0,0,0,0,0,0,2,0,120,0,0,FFFF$';
                        case 'disarm':
                            return 'AT+GTOUT=gv57,0,0,0,0,0,0,0,0,0,0,0,0,2,0,120,0,0,FFFF$';
                    }
                }
                break;
            
            case 'atrack':
                return 'AT$GPOS=2';

            case 'calamp':
                return '!R3,44,11';
        }
        return undefined;
    }

    static getResetCommand(deviceType: OutputSupportedDeviceType): string | void {
        switch (`${deviceType}`.toLowerCase()) {
            case 'calamp':
                return "!R3,70,0";

            // TODO: Add reset command for Atrack as we do have the command for it.
        }
        return undefined;
    }

    static getRecoverCommand(deviceType: OutputSupportedDeviceType): string | void {
        switch (`${deviceType}`.toLowerCase()) {
            case 'calamp':
                return "!R2";
        }
        return undefined;
    }

    getGSMSignalStrength(): number {
        const value = this.getCompositeStateItem('signal_strength')?.getIntValue();

        return value ? (value / 5) * 100 : 0;
    }


    public getCommand(commandType: EDeviceCommandTypes, type?: string): string | void {
        const deviceType = this.getTagValue('device_type') as OutputSupportedDeviceType;
        const deviceModel = this.getTagValue('device_model') as string;

        switch (commandType) {
            case EDeviceCommandTypes.ACTIVATE_OUTPUT_0:
                return DeviceBase.getOutputCommand(deviceType, 0, true);

            case EDeviceCommandTypes.DEACTIVATE_OUTPUT_0:
                return DeviceBase.getOutputCommand(deviceType, 0, false);

            case EDeviceCommandTypes.ACTIVATE_OUTPUT_1:
                return DeviceBase.getOutputCommand(deviceType, 1, true, deviceModel);

            case EDeviceCommandTypes.DEACTIVATE_OUTPUT_1:
                return DeviceBase.getOutputCommand(deviceType, 1, false, deviceModel);

            case EDeviceCommandTypes.ACTIVATE_RECOVERY_0:
                return DeviceBase.getOutputCommand(deviceType, 2, true);

            case EDeviceCommandTypes.DEACTIVATE_RECOVERY_0:
                return DeviceBase.getOutputCommand(deviceType, 2, false);

            case EDeviceCommandTypes.RESET:
                return DeviceBase.getResetCommand(deviceType);

            case EDeviceCommandTypes.RECOVER:
                return DeviceBase.getRecoverCommand(deviceType);

            case EDeviceCommandTypes.LOCATE:
            default:
                return DeviceBase.getLocateCommand(deviceType, deviceModel, type);
        }
    }
}

export const DeviceBaseSerializer = new TypedJSON(DeviceBase);

export type StoredDevice = Partial<DeviceBase> & { id: string };
