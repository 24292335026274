import React, {useMemo} from 'react';
import {Card, ConfigProvider, Layout} from 'antd';
import logo from 'assets/svg/logo.svg';

import './holotrak-card-container.scss';
import {Link} from "react-router-dom";

const {Content} = Layout;


interface ICardContainerProps {
    children: React.ReactNode[];
}

export const CardContainer: React.FC<ICardContainerProps> = (props) => {
    const theme = useMemo(() => {
        const colorPrimaryActive = "#f9f9f9";
        return ({
            "components": {
                "Input": {
                    "colorBorder": colorPrimaryActive,
                    "borderRadius": 0,
                    "colorTextPlaceholder": "#000",
                    "colorPrimaryActive": colorPrimaryActive,
                    "colorPrimaryHover": colorPrimaryActive,
                    "colorPrimary": colorPrimaryActive,
                    "controlOutline": colorPrimaryActive
                },
                "Button": {
                    "borderRadius": 0,
                    "borderRadiusLG": 0,
                    "borderRadiusSM": 0,
                    "colorPrimary": "#000",
                    "colorPrimaryHover": "rgba(0,0,0,0.32)",
                }
            },
            "token": {}
        });
    }, []);


    return (
        <>
            <ConfigProvider theme={theme}>
                <Layout className={'container-card--holotrak'}>
                    <Content>
                        <Card
                            className={'holotrak-card'}
                        >
                            <Link to={'/'} className='holotrak-logo'>
                                <img src={logo} alt={'Holotrak Logo'}/>
                            </Link>
                            {props.children}
                        </Card>
                    </Content>
                </Layout>
            </ConfigProvider>
        </>
    );
}
