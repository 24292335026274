import {prepareDataForExport, toCsv} from "./exportUtils";
import jsPDF from 'jspdf';
import autoTable, {UserOptions} from 'jspdf-autotable'
import {TableProps as RcTableProps} from "rc-table/lib/Table";
import {HolotrakColumnConfig} from "../components/paginated-list";
import logoForPDF from 'assets/images/pdf-logo.png';

export const exportToPdf = (key: string, title: string, records: RcTableProps<any>['data'], columns: HolotrakColumnConfig) => {
    const data = prepareDataForExport(records, columns);

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape
    const marginLeft = 20;

    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    doc.setFont('Nunito Sans');

    const img = new Image();
    img.src = logoForPDF;


    if (data.length > 0) {
        const headers = Object.keys(data[0]).map((key) => {
            return key;
        });
        const content: UserOptions = {
            startY: 50,
            head: [headers],
            body: toCsv(data),
            headStyles: {
                fillColor: '#DFE3EF'
            }
        }

        autoTable(doc, content);
    }


    // Loop through each page to add the header and footer
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);

        // Add header
        doc.text(title, marginLeft, 25);
        doc.addImage(img, 'png', doc.internal.pageSize.width - 100, 20, 80, 6);

        // Add footer
        const footer = `Page ${i} of ${doc.getNumberOfPages()}`;
        doc.text(footer, doc.internal.pageSize.width - 80, doc.internal.pageSize.height - 10);
    }

    doc.save(`${title}-${Date.now()}.pdf`);
}
