import {Button, Form, Input, message, Typography} from 'antd';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useForgetPasswordMutation} from "redux/services/losant";
import {CardContainer} from "../../components/card-container";

const {Text, Link} = Typography;

export const ForgetPassword = () => {
    const [form] = Form.useForm();
    const [requestNewPassword, {isLoading}] = useForgetPasswordMutation();

    const onFinish = async ({email}) => {
        await requestNewPassword({email});

        message.success("You should receive an email shortly with instructions to reset password.");
    };
    return (
        <CardContainer>
            <Helmet>
                <title>Forgot Password | Request New</title>
                <meta name="description" content="Request a new password"/>
            </Helmet>
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                            type: 'email'
                        },
                    ]}
                >
                    <Input placeholder="Username or Email"/>
                </Form.Item>

                <Button
                    loading={isLoading}
                    htmlType="submit"
                    type="primary"
                    className="main-btn"
                >
                    Send Email
                </Button>

                <Text className='password'>
                    <Link href='/login' className='recover'> Login Again.</Link>
                </Text>

            </Form>
        </CardContainer>
    );
}

export default ForgetPassword;
