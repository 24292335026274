import {Button, Form, Input, message, Typography} from 'antd';
import React, {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useResetPasswordMutation} from "redux/services/losant";
import {CardContainer} from "../../components/card-container";
import {useLocation} from "react-router-dom";
import {isIResetPasswordResponse} from "../../contracts/IForgetPassword";

const {Text, Link} = Typography;

export const ResetPassword = () => {

    const {search} = useLocation();
    const token = useMemo<string>(() => new URLSearchParams(search).get('token'), [search]);

    const [form] = Form.useForm();
    const [resetNewPassword, {isLoading}] = useResetPasswordMutation();

    const onFinish = async ({password}) => {
        resetNewPassword({password, token}).then((response) => {
            if ('data' in response && isIResetPasswordResponse(response.data)) {
                if (response.data.success) {
                    message.success(response.data.success.message);
                } else if (response.data.error) {
                    message.error(response.data.error.message);
                } else {
                    message.error("Password reset failed.");
                }
            } else {
                message.error("Password reset failed.");
            }
        });
    };
    return (
        <CardContainer>
            <Helmet>
                <title>Forgot Password | Request New</title>
                <meta name="description" content="Request a new password"/>
            </Helmet>
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password',
                        },
                        {
                            min: 8,
                            message: 'Password must be at least 8 characters long'
                        }
                    ]}
                >
                    <Input.Password placeholder="Password"/>
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password',
                        },
                        {
                            min: 8,
                            message: 'Password must be at least 8 characters long'
                        },
                        // Should match the password
                        {
                            validator: (_, value) => {
                                if (value !== form.getFieldValue('password')) {
                                    return Promise.reject('Passwords do not match');
                                }
                                return Promise.resolve();
                            },
                        }
                    ]}
                >
                    <Input.Password placeholder="Confirm Password"/>
                </Form.Item>

                <Button
                    loading={isLoading}
                    htmlType="submit"
                    type="primary"
                    className="main-btn"
                >
                    Send Email
                </Button>

                <Text className='password'>
                    <Link href='/login' className='recover'> Login Again.</Link>
                </Text>

            </Form>
        </CardContainer>
    );
}

export default ResetPassword;
