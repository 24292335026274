export class IndoorPosition {
    0: number;
    1: number;
    2: number;

    constructor(x: number, y: number, z: number) {
        this["0"] = x;
        this["1"] = y;
        this["2"] = z;
    }

    get x(): number {
        return this["0"];
    }

    get y(): number {
        return this["1"];
    }

    get z(): number {
        return this["2"];
    }

    toString(): string {
        return `${this.x}, ${this.y}, ${this.z}`;
    }
}
