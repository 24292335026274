import { createSlice } from '@reduxjs/toolkit';
import { message } from "antd";
import { losantApi } from 'redux/services/losant';
import { RootState } from 'redux/store';
import { AuthResponse } from "contracts/holotrak/authentication";

export const AUTH_KEY = 'auth';
const persistedAuth =
    sessionStorage.getItem(AUTH_KEY) || localStorage.getItem(AUTH_KEY);

const initialState: AuthResponse = persistedAuth
    ? JSON.parse(persistedAuth)
    : {};

export const authSlice = createSlice({
    name: AUTH_KEY,
    initialState,
    reducers: {
        setAccessToken: (state, { payload }) => {
            state.accessToken = payload;
        },
        logout: (state) => {
            state.accessToken = null;
            state.user = null;
            sessionStorage.removeItem(AUTH_KEY);
            localStorage.removeItem(AUTH_KEY);
        },
    },
    extraReducers: (builder) => {

        // Add matcher for all failed requests


        builder.addMatcher(
            losantApi.endpoints.getCurrentUser.matchFulfilled,
            (state, { payload }) => {
                state.user = payload;
                sessionStorage.setItem(AUTH_KEY, JSON.stringify({ accessToken: state.accessToken, user: payload }));
            }
        );

        builder.addMatcher(
            losantApi.endpoints.loginUser.matchFulfilled,
            (state, { payload }) => {
                if ('accessToken' in payload && 'user' in payload) {
                    const { accessToken, user } = payload;
                    state.accessToken = accessToken;
                    state.user = user;
                    sessionStorage.setItem(AUTH_KEY, JSON.stringify({ accessToken, user }));
                    
                } else {
                    state.accessToken = null;
                    state.user = null;
                    sessionStorage.removeItem(AUTH_KEY);
                    payload.error.message && message.error(payload.error.message).then(r => console.log(r));
                }
            }
        );

        builder.addMatcher(
            losantApi.endpoints.loginRememberedUser.matchFulfilled,
            (state, { payload }) => {
                if ('accessToken' in payload && 'user' in payload) {
                    const { accessToken, user } = payload;
                    state.accessToken = accessToken;
                    state.user = user;
                    localStorage.setItem(AUTH_KEY, JSON.stringify({ accessToken, user }));
            
                } else {
                    state.accessToken = null;
                    state.user = null;
                    localStorage.removeItem(AUTH_KEY);
                    payload.error.message && message.error(payload.error.message).then(r => console.log(r));
                }
            }
        );
    },
});

export default authSlice.reducer;
export const { logout, setAccessToken } = authSlice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;
