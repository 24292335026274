import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/es/components/Icon";
import React from "react";
import SVG from "react-inlinesvg";
import image from "../svg/maintenances-menu2.svg";

export const IconMaintenanceMenu2 = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => <SVG src={image} />} {...props} />
);

