import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import React, {useMemo} from 'react';
import {BooleanIcon} from "../../assets/icons/boolean-icon";
import {DateTimeRenderer} from "../../components/datetime";
import PaginatedList from "../../components/paginated-list";

import {EResource} from "../../contracts/EResource";
import {useGetNotificationListQuery} from "../../redux/services/losant/notifications";
import useTablePagination from "../../utils/useTablePagination";


export const Notifications: React.FC = () => {
    const {
        pagination,
        searchTerm,
        handleTableChange,
        handleSearch,
        sorting,
    } = useTablePagination({}, {order: 'descend', field: 'createdAt'});

    const {isLoading, data: tableResponse} = useGetNotificationListQuery({
        page: pagination.current - 1,
        limit: pagination.pageSize,
        fieldValue: 'deviceName', // Perhaps we can change it to better
        searchValue: searchTerm,
        sort: sorting.order,
    });

    const columns = useMemo(() => ([
        {
            title: 'Rule',
            key: 'rule',
            dataIndex: 'alertName', 
            render: (item, record) => {
                const name = item || record?.rule;
                return name?.charAt(0).toUpperCase() + name?.slice(1);
            },
        },
        {
            title: 'Device Name',
            key: 'device',
            dataIndex: 'deviceName',
        },
        {
            title: 'User',
            key: 'user',
            dataIndex: 'user',
            render: (item, record) =>
                `${record.userFirstName} ${record.userLastName}`,
        },
        {
            title: 'Location',
            key: 'location',
            dataIndex: 'location',
            render: item => (item === null ? '--' : item),
        },
        {
            title: 'Address',
            key: 'address',
            dataIndex: 'address',
            render: item => (item === null ? '--' : item),
        },
        {
            title: 'Email Sent',
            key: 'email',
            dataIndex: 'email',
            renderData: item => (item && item === true) || item === 'true' ? 'Yes' : 'No',
            render: item => <BooleanIcon value={item}/>,
        },
        {
            title: 'Sms Sent',
            key: 'sms',
            dataIndex: 'sms',
            renderData: item => (item && item === true) || item === 'true' ? 'Yes' : 'No',
            render: item =>
                (item && item === true) || item === 'true' ? (
                    <CheckOutlined
                        style={{
                            fontSize: '10px',
                            background: '#9CE8D7',
                            borderRadius: '50%',
                            padding: '8px',
                        }}
                    />
                ) : (
                    <CloseOutlined
                        style={{
                            fontSize: '10px',
                            background: '#FFC3C5',
                            borderRadius: '50%',
                            padding: '8px',
                        }}
                    />
                ),
        },
        {
            title: 'Generated',
            key: 'generated',
            dataIndex: 'createdAt',
            render: (item) => <DateTimeRenderer date={item}/>,
            sorter: true
        },
        {
            title: 'Updated',
            key: 'updated',
            dataIndex: 'updatedAt',
            render: (item) => <DateTimeRenderer date={item}/>,
        },
    ]), []);

    return (
        <PaginatedList
            title="Notifications"
            resource={EResource.NOTIFICATIONS}
            onSearch={handleSearch}
            onChange={handleTableChange}
            pagination={pagination}
            isLoading={isLoading}
            dataSource={tableResponse?.items}
            columns={columns}
            totalCount={tableResponse?.totalCount}
        />
    );
};

export default Notifications;
