import {Button, Drawer, Form, Input, Select, Space, Spin, Typography, Upload} from "antd";
import {CloseOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";

import React, {useCallback, useEffect, useMemo} from "react";
import {useAppDispatch, useTypedSelector} from "../../../redux/store";
import {
    selectGeofenceDraw,
    setGeofenceDraw,
    setGeofenceKmzKey,
    toggleGeofenceFromList
} from "../../../redux/slices/app";
import {useCreateGeofenceMutation, useEditGeofenceMutation} from "../../../redux/services/losant/geofences";
import {Geofence, geofenceSerializer} from "../../../contracts/holotrak/geofence";

import type {UploadChangeParam} from 'antd/es/upload';
import type {UploadFile, UploadProps} from 'antd/es/upload/interface';
import {selectCurrentUser} from "../../../redux/slices/auth";
import {useDeleteFileMutation} from "../../../redux/services/express/files";

import '../core/geofence-manager.scss';

interface IGeofenceFormDrawerProps {
    open: boolean;
    toggle: () => void;
}

// const uploadFileActionURL = "" as const;
const uploadFileActionURL = `${process.env.REACT_APP_EXPRESS_SERVER}upload`;

export const GeofenceFormDrawer: React.FC<IGeofenceFormDrawerProps> = ({open, toggle}) => {
    const [geoFenceForm] = Form.useForm();
    const appDispatch = useAppDispatch();

    const [createGeofence, {isLoading: isCreatingGeofence}] = useCreateGeofenceMutation();
    const [editGeofence, {isLoading: isEditingGeofence}] = useEditGeofenceMutation();
    const [deleteFileFromServer, {isLoading: isDeletingFile}] = useDeleteFileMutation();

    const user = useTypedSelector(selectCurrentUser);
    const drawnGeofence = useTypedSelector(selectGeofenceDraw);

    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [newlyUploadedFile, setNewlyUploadedFile] = React.useState<string | null>(null);

    const uploadButton = useMemo(() => (
        <div className={'geofence-upload-button'}>
            {isUploading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>
                <Typography.Text>Upload File</Typography.Text>
                <br/>
                <Typography.Text type="secondary">Only KMZ and KML files supported</Typography.Text>
            </div>
        </div>
    ), [isUploading]);

    const fileUploadHeaders = useMemo(() => {
        const authToken = (user.experienceGroups && user.experienceGroups[0])
            ? btoa(user.experienceGroups[0].id)
            : null;

        return {
            Authorization: `Bearer ${authToken}`
        };
    }, [user.experienceGroups]);

    const handleGeoFormSubmit = useCallback(() => {
        geoFenceForm.validateFields().then(async (values) => {
            const geofence = {...drawnGeofence} as Geofence;

            if (geofence.id && geofence.type === 'kml' && geofence.shape && values.file !== geofence.shape) {
                deleteFileFromServer(geofence.shape);
            } else {
                // TODO: Check if saved Geofence type was KML and the file was changed so those could be deleted.
            }

            geofence.name = values.name;
            geofence.subType = values.subType;
            geofence.notes = values.notes;
            if (values.file) {
                geofence.shape = values.file;
                geofence.type = "kml";
            }

            const response = (drawnGeofence?.id) ? await editGeofence(geofence) : await createGeofence(geofence);

            if ("data" in response) {
                const newlyCreatedGeofence = geofenceSerializer.parse(response.data);
                appDispatch(setGeofenceDraw(newlyCreatedGeofence));
                appDispatch(toggleGeofenceFromList(newlyCreatedGeofence));
                appDispatch(setGeofenceKmzKey(null));

                setNewlyUploadedFile(null);
            }
        });
    }, [appDispatch, createGeofence, deleteFileFromServer, drawnGeofence, editGeofence, geoFenceForm]);

    const handleGeoFormSubmitFailed = useCallback((errorInfo) => {
        console.log("Failed:", errorInfo);
    }, []);

    const removeCandidateKML = useCallback(() => {
        // Could be that the Geofence is new and no file is uploaded
        // or that the Geofence is existing and the type is not KML
        const key = geoFenceForm.getFieldValue('file');

        geoFenceForm.setFieldsValue({
            file: null
        });

        // If by any chance the Geofence is new and file was uploaded.
        if (!drawnGeofence?.id) {
            deleteFileFromServer(key);
            if (newlyUploadedFile) {
                deleteFileFromServer(newlyUploadedFile);
                setNewlyUploadedFile(null);
            }
        } else {
            if (newlyUploadedFile && key !== newlyUploadedFile) {
                deleteFileFromServer(newlyUploadedFile);
                setNewlyUploadedFile(null);
            }
        }

        appDispatch(setGeofenceKmzKey(null));
    }, [appDispatch, newlyUploadedFile, deleteFileFromServer, drawnGeofence?.id, geoFenceForm]);

    const handleDrawerClose = useCallback(() => {
        removeCandidateKML();
        geoFenceForm.resetFields();

        appDispatch(setGeofenceDraw(null));
        toggle();
    }, [geoFenceForm, removeCandidateKML, appDispatch, toggle]);

    const handleFileUploadChange: UploadProps['onChange'] = useCallback((info: UploadChangeParam<UploadFile>) => {
        switch (info.file.status) {
            case 'uploading':
                setIsUploading(true);
                break;

            case 'done':
                setIsUploading(false);
                removeCandidateKML();
                geoFenceForm.setFieldsValue({
                    file: info.file.response.file.key
                });

                appDispatch(setGeofenceKmzKey(info.file.response.file.key));
                appDispatch(setGeofenceDraw({
                    id: drawnGeofence?.id || null,
                    shape: info.file.response.file.key,
                    type: "kml",
                }));
                setNewlyUploadedFile(info.file.response.file.key);

                break;

            case 'error':
                setIsUploading(false);
                setNewlyUploadedFile(null);
                break;
        }
    }, [appDispatch, geoFenceForm, removeCandidateKML]);

    useEffect(() => {
        if (drawnGeofence?.id) {
            geoFenceForm.setFieldsValue({
                name: drawnGeofence.name,
                subType: drawnGeofence.subType,
                notes: drawnGeofence.notes,
                file: drawnGeofence.type === 'kml' ? drawnGeofence.shape : null
            });
        }
    }, [drawnGeofence, geoFenceForm]);

    return (
        <Drawer
            title={`${drawnGeofence?.id ? 'Edit' : 'Create'} Geofence`}
            closable={false}
            mask={false}
            onClose={handleDrawerClose}
            open={open}
            extra={
                <Space>
                    <Button
                        htmlType="submit"
                        className="grey-btn"
                        onClick={handleGeoFormSubmit}
                    >
                        {drawnGeofence?.id ? 'Update' : 'Create'}
                    </Button>

                    <Button
                        onClick={handleDrawerClose}
                        type="link"
                        icon={<CloseOutlined/>}
                    />
                </Space>
            }
        >
            <Spin spinning={isCreatingGeofence || isEditingGeofence}>
                <Form
                    name="geofence"
                    form={geoFenceForm}
                    onFinishFailed={handleGeoFormSubmitFailed}
                >
                    <Form.Item
                        name="name"
                        label="Fence Name"
                        rules={[
                            {
                                required: true,
                                message: "Please Input GeoFence Name!"
                            }
                        ]}
                    >
                        <Input placeholder="RMT HQ"/>
                    </Form.Item>

                    <Form.Item
                        name="subType"
                        label="Type"
                        rules={[
                            {
                                required: false,
                                message: "Please Select Type"
                            }
                        ]}
                    >
                        <Select placeholder="Company Location">
                            <Select.Option value="yard">Yard</Select.Option>
                            <Select.Option value="companyLocation">
                                Company Location
                            </Select.Option>
                            <Select.Option value="customerLocation">
                                Customer Location
                            </Select.Option>
                            <Select.Option value="other">Other</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="notes" label="Notes">
                        <Input placeholder="Add Notes"/>
                    </Form.Item>

                    <Form.Item name="file" label="KML File Key" hidden>
                        <Input placeholder=".kmz" readOnly/>
                    </Form.Item>

                </Form>

                <Upload
                    name="file"
                    accept={'.kml,.kmz'}
                    action={uploadFileActionURL}
                    headers={fileUploadHeaders}
                    maxCount={1}
                    className="geofence-uploader"
                    listType="picture-card"
                    showUploadList={false}
                    onChange={handleFileUploadChange}
                >
                    {uploadButton}
                </Upload>

            </Spin>
        </Drawer>
    )
}
