import {isRouteErrorResponse, Link, useRouteError} from "react-router-dom";
import {CardContainer} from "../../components/card-container";
import {Helmet} from 'react-helmet';
import {Button, Col, Row, Typography} from "antd";
import React from "react";

const {Title, Text} = Typography;

export default function PageNotFound() {
    const error: any = useRouteError();

    let errorMessage: String;
    if (isRouteErrorResponse(error)) {
        errorMessage = ('message' in error && error.message instanceof String)
            ? error.message as String
            : error.statusText;
    } else {
        errorMessage = 'Unknown error';
    }

    return (
        <CardContainer>
            <Helmet>
                <title>{errorMessage}</title>
            </Helmet>

            <Row style={{padding: '2em', margin: '2em'}}>
                <Col span={24}>
                    <Title level={3} style={{textAlign: 'center'}}>Oops!</Title>
                    <Text>Sorry, an unexpected error has occurred.</Text>
                    <br/>
                    <Text italic>
                        {errorMessage}
                    </Text>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Link to={'/'}>
                        <Button type="primary" block>
                            Reload
                        </Button>
                    </Link>
                </Col>
            </Row>

        </CardContainer>
    );
}
