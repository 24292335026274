import React, {useEffect} from "react";
import {useGetCurrentUserQuery} from "redux/services/losant";
import {setAccessToken} from "redux/slices/auth";
import {useAppDispatch} from "redux/store";
import {useLocation, useNavigate} from "react-router-dom";
import {Spin} from "antd";


export const AdminLogin: React.FC = () => {
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // Access specific query parameters
    const parameterValue = searchParams.get('accessToken');

    appDispatch(setAccessToken(parameterValue || ''));
    const {isLoading, data} = useGetCurrentUserQuery(undefined, {skip: !parameterValue});

    useEffect(() => {
        if (data) {
            navigate('/');
        }
    }, [data, navigate]);

    return (
        <Spin spinning={isLoading || !parameterValue}>
            Loading user...
        </Spin>
    );
}

export default AdminLogin;
