import React from "react";
import {useSensorModelName} from "utils/useSensorModelName";

interface ISensorModelNameProps {
    model: string;
}

export const SensorModelName: React.FC<ISensorModelNameProps> = ({model}) => {
    const {modelName: deviceSensorName} = useSensorModelName(model);

    return (
        <>
            {deviceSensorName}
        </>
    )
}
