import axios from 'axios';
import React, { useEffect } from 'react';
import { selectUserIpInfo, setUserIpInfo } from 'redux/slices/app';
import { useAppDispatch, useTypedSelector } from 'redux/store';

export const useGeolocationInfo = () => {
    const userIpInfo = useTypedSelector(selectUserIpInfo);

    const dispatch = useAppDispatch();

    useEffect(() => {
        // axios.get('https://geolocation-db.com/json/')
        axios.get('https://ipapi.co/json')
            .then((response: any) => {

                let userIpInfo = {
                        latitude: response?.data?.latitude || '',
                        longitude: response?.data?.longitude || '',
                        IPv4: response?.data?.ip || '',
                        country_code: response?.data?.country_code || '',
                        country_name: response?.data?.country_name || '',
                        postal: response?.data?.postal || '',
                        state: response?.data?.region || ''
                    }
                    dispatch(
                            // setUserIpInfo(response?.data)
                            setUserIpInfo(userIpInfo)
                        );
            })
    }, []);

    return userIpInfo;
}