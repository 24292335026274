import {useCallback, useState} from 'react';
import dayjs from "dayjs";
import {RangePickerProps} from "antd/lib/date-picker";


export const useDateRange = (startDate?: dayjs.Dayjs, endDate?: dayjs.Dayjs) => {

    const [startDateRange, setStartDateRange] = useState<dayjs.Dayjs>(
        startDate || dayjs().subtract(20, 'day')
    );
    const [endDateRange, setEndDateRange] = useState<dayjs.Dayjs>(
        endDate || dayjs()
    );

    const disabledDateForStart: RangePickerProps['disabledDate'] = useCallback((current) => {
        // Can not select days before today and today
        return current > endDateRange.endOf('day');
    }, [endDateRange]);

    const disabledDateForEnd: RangePickerProps['disabledDate'] = useCallback((current) => {
        // Can not select days before today and today
        return current < startDateRange.startOf('day');
    }, [startDateRange]);

    const handleStartDateChange = useCallback((date: any, dateString: string) => {
        date && setStartDateRange(dayjs(dateString));
    }, []);

    const handleEndDateChange = useCallback((date: any, dateString: string) => {
        date && setEndDateRange(dayjs(dateString));
    }, []);


    return {
        startDateRange,
        endDateRange,
        disabledDateForStart,
        disabledDateForEnd,
        handleStartDateChange,
        handleEndDateChange
    }
}
