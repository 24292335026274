import React from 'react';
import {Button, Col, message, Popconfirm, Row, Space, Spin, Tooltip, Typography} from "antd";
import {Helmet} from "react-helmet";
import {useDeleteReportMutation, useGetReportsListQuery} from "redux/services/express/reports";

import useTablePagination from "utils/useTablePagination";
import {useDateRange} from "utils/useDateRange";
import {ItemsListContext} from "utils/itemsListContext";
import {GroupedItemsProvider} from "utils/groupedItemsContext";

import {Report, ReportSerializer} from "contracts/holotrak/report";
import {EGroupType} from "contracts/EGroupType";

import {IconTrash} from "assets/icons/icon-trash";
import blankReportFallback from "assets/images/blank-report.png";

import './reports.scss';
import useToggleValue from "../../utils/useToggleValue";
import ReportCreation from "../../components/reports/ReportCreation";
import AccessControl from "../../components/core/AccessControl";
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";

const {Title} = Typography;
const ItemsPane = React.lazy(() => import("components/item-pane/ItemsPane"));
const ReportRenderer = React.lazy(() => import("components/reports/ReportRenderer"));
const FilterReport = React.lazy(() => import("components/item-filters/FilterReport"));
export const HistoricalReports: React.FC = () => {
    const {
        pagination,
        searchTerm,
        handleSearch
    } = useTablePagination();

    const [currentReport, setCurrentReport] = React.useState<Report | null>(null);


    const [isCreatingReport, toggleReportCreation] = useToggleValue(false);

    const {
        startDateRange,
        endDateRange,
        disabledDateForStart,
        disabledDateForEnd,
        handleStartDateChange,
        handleEndDateChange
    } = useDateRange();


    const {isLoading, data: tableResponse, error: tableError} = useGetReportsListQuery({
        page: pagination.current,
        limit: pagination.pageSize,
        startDateRange: startDateRange.startOf('day').toISOString(),
        endDateRange: endDateRange.endOf('day').toISOString(),
        fieldKey: 'name', // Older system only used names
        fieldValue: searchTerm,
        searchValue: '',
    }, {
        selectFromResult: ({data, isLoading, error}) => ({
            isLoading,
            error,
            data: {
                ...data,
                items: data?.items.map((item) => ReportSerializer.parse(item)) || []
            }
        }),
    });
    const [deleteReport, {isLoading: isDeletingReport}] = useDeleteReportMutation();

    return (
        <React.Suspense fallback={<Spin spinning={true}/>}>
            <Helmet titleTemplate="%s | Reports | Holotrak">
                <meta name='description' content='Historical Reports'/>
            </Helmet>

            <AccessControl
                resource={EResource.REPORTS}
                permissionNeeded={EPermission.READ}
                render={() => (
                    <GroupedItemsProvider groupType={EGroupType.REPORT}>
                        <ItemsListContext.Provider value={{
                            selectedItemId: currentReport?.id,
                        }}>
                            <Row className='historical-reports'>
                                <Col span={6} className='historical-reports--list'>
                                    <ItemsPane
                                        controlLeftDrawer={false}
                                        error={tableError}
                                        loading={isLoading || isDeletingReport}
                                        items={tableResponse?.items}
                                        total={tableResponse?.items?.length}
                                        titleActions={(
                                            <>
                                                <AccessControl
                                                    sidebar
                                                    resource={EResource.REPORTS}
                                                    permissionNeeded={EPermission.CREATE}

                                                    render={p => (
                                                        <Button
                                                            onClick={toggleReportCreation}
                                                            title="Create Report"
                                                            type="primary"
                                                        >
                                                            Add Report
                                                        </Button>
                                                    )}
                                                />
                                            </>
                                        )}
                                        onItemClick={(item) => {
                                            setCurrentReport(item as Report);
                                        }}
                                        filter={() => <FilterReport
                                            handleSearch={handleSearch}
                                            searchTerm={searchTerm}
                                            startDateRange={startDateRange}
                                            endDateRange={endDateRange}
                                            disabledDateForStart={disabledDateForStart}
                                            disabledDateForEnd={disabledDateForEnd}
                                            handleStartDateChange={handleStartDateChange}
                                            handleEndDateChange={handleEndDateChange}
                                        />}
                                        actions={item => {
                                            return (
                                                <Space>
                                                    <AccessControl
                                                        resource={EResource.REPORTS}
                                                        permissionNeeded={EPermission.DELETE}
                                                        render={() => (
                                                            <Tooltip placement={'bottom'} title={`Delete Report`}>
                                                                <Popconfirm
                                                                    title={`Are you sure you want to delete ${item.name}?`}
                                                                    onCancel={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onConfirm={async (e) => {
                                                                        e.stopPropagation();
                                                                        deleteReport(item.id).then(() => {
                                                                            return message.success(`Report deleted successfully`);
                                                                        });
                                                                    }}>
                                                                    <Button shape={'circle'} icon={<IconTrash/>}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}/>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        )}/>
                                                </Space>
                                            );
                                        }}
                                    />
                                </Col>
                                <Col span={18} className='historical-reports--renderer'>
                                    {
                                        currentReport
                                            ? (
                                                <Row>
                                                    <Col span={24}>
                                                        {
                                                            currentReport && <ReportRenderer report={currentReport}/>
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                            : (
                                                <div className="report-data-blank">
                                                    <img className="image"
                                                         src={blankReportFallback}
                                                         alt='Select Report'/>

                                                    <div>
                                                        <Title level={2}>No Report Selected</Title>
                                                        <Title level={3}>Select a Report to View Data</Title>
                                                    </div>

                                                </div>
                                            )
                                    }
                                </Col>
                            </Row>

                            <ReportCreation toggleReportCreation={toggleReportCreation}
                                            isCreatingReport={isCreatingReport}/>

                        </ItemsListContext.Provider>
                    </GroupedItemsProvider>
                )}/>
        </React.Suspense>
    );
};

export default HistoricalReports;
