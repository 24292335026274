import {
    Badge,
    Button,
    Col,
    Input,
    Layout,
    Modal,
    Row,
    Spin,
    Table,
    TablePaginationConfig,
    theme,
    Typography,
} from "antd";
import { FormInstance } from "antd/es/form";
import { FilterValue, SorterResult, TableCurrentDataSource, TableRowSelection } from "antd/es/table/interface";

import iconGridView from "assets/svg/icon-grid-view.svg";
import iconListView from "assets/svg/icon-list-view.svg";

import TableGrid from "components/table-grid";
import { RowClassName } from "rc-table/lib/interface";
import { TableProps as RcTableProps } from "rc-table/lib/Table";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { EPermission } from "../../contracts/EPermission";
import { EResource } from "../../contracts/EResource";

import useToggleValue from "../../utils/useToggleValue";
import AccessControl from "../core/AccessControl";
import { EditableCell, EditableRow } from "./EditableCell";

import "./paginated-list.scss";
import { IconMaintenanceMenu } from "assets/icons/Icon-maintenance-menu";

import { IconMaintenanceCal } from "assets/icons/Icon-maintenance-cal";

import { useNavigate } from "react-router-dom";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import { IBack } from "assets/icons/icon-back";

import { IconMaintenanceMenu2 } from "assets/icons/Icon-maintenance-menu2";
import { IconMaintenanceCal2 } from "assets/icons/Icon-maintenance-cal2";
import MaintenanceCalender from "pages/maintenanceCal/MaintenanceCalender";
import { AddSchedule } from "pages/maintenance/AddSchedule";
import { AddMaintenanceLog } from "pages/maintenance/AddMaintenanceLog";

const { Title } = Typography;
const { Header, Content, Footer } = Layout;

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
export type EditableColumn = {
    dataIndex: string;
    editable?: boolean;
    column?: boolean;
    handleSave?: (record: unknown) => void;
    component?: (form: FormInstance<any>, onSave: () => void) => React.ReactNode;
    action?: (form: FormInstance<any>, onSave: () => void) => React.ReactNode;

    exportable?: boolean;
    renderData?: (item: unknown, record: unknown) => string;

    isGridHeader?: boolean;
    isGridContent?: boolean;
};
export type HolotrakColumnConfig = (ColumnTypes[number] & EditableColumn)[];

export type PrepareExport<RecordType> = (
    data: RcTableProps<RecordType>["data"]
) => Promise<RcTableProps<RecordType>["data"]>;

interface IPaginatedListProps<RecordType> {
    title: string;
    subTitle?: string;
    onSearch?: (
        value: string,
        event?:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLInputElement>
    ) => void;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
        extra: TableCurrentDataSource<RecordType>
    ) => void;

    dataSource?: RcTableProps<RecordType>["data"];
    prepareExport?: PrepareExport<RecordType>;

    showTotal?: boolean;
    totalCount?: number;
    pagination?: false | TablePaginationConfig;

    isLoading?: boolean;

    tableClassName?: string;

    columns?: HolotrakColumnConfig;
    rowSelection?: TableRowSelection<RecordType>;
    rowClassName?: string | RowClassName<RecordType>;

    allowExport?: boolean;
    controlPageTitle?: boolean;
    controlHeader?: boolean;
    hasGridView?: boolean;

    beforeExportSlot?: React.ReactNode;
    additionalHeader?: React.ReactNode;
    beforeContent?: React.ReactNode;
    afterContent?: React.ReactNode;

    resource: EResource;

    gridConfig?: IGridConfig;

    isCompact?: boolean;
    isModalVisible?: any;
    setIsModalVisible?: any;
}

interface IGridConfig {
    title: (item: any) => React.ReactNode;

    [key: string]: (item: any) => React.ReactNode | Function | any;
}

const MaintenanceList: React.FC<IPaginatedListProps<any>> = (props) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [isAddSchedule, toggleAddSchedule] = useToggleValue(false);
    const [isAddMaintenanceLog, toggleAddMaintenanceLog] = useToggleValue(false);
    const navigate = useNavigate();

    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const [usedHeight, setUsedHeight] = useState(0);
    const [isGridView, toggleGridView] = useToggleValue(false);
    const [activeIcon, setActiveIcon] = useState("menu");

    const handleClick = (type: string) => {
        setActiveIcon(type); // Set the clicked icon as active

        // Navigate to the respective page based on the clicked icon
        if (type === "menu") {
            setActiveIcon("menu");
            navigate("/maintenance");
            console.log("menu clicked");
        } else if (type === "cal") {
            navigate("/maintenance-cal");
            setActiveIcon("cal");
        }
    };

    const handleOpenModal = () => {
        toggleAddSchedule();
    };

    const handleCloseModal = () => {
        toggleAddSchedule();
    };
    const handleAddLogOpenModal = () => {
        toggleAddMaintenanceLog();
    };

    const handleAddLogCloseModal = () => {
        toggleAddMaintenanceLog();
    };
    useEffect(() => {
        if (headerRef.current || footerRef.current) {
            setUsedHeight((headerRef.current?.clientHeight ?? 0) + (footerRef.current?.clientHeight ?? 0));
        }
    }, []);

    const showHelmetTitle = props.controlPageTitle ?? true;
    const showHeader = props.controlHeader ?? true;
    const hasGridView = props.hasGridView ?? false;
    const showTotal = props.showTotal ?? false;

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const contentStyle = useMemo<CSSProperties>(() => {
        if (props.isCompact === undefined || props.isCompact === false)
            return {
                height: `calc(100vh - ${usedHeight + 8}px)`,
                overflowY: "auto",
            };

        return {};
    }, [props.isCompact, usedHeight]);

    const items: CollapseProps["items"] = [
        {
            key: "1",
            label: "JEEP1",
            children: (
                <>
                    <Table
                        rowKey="id"
                        className={`holotrak-table ${props.tableClassName ? props.tableClassName : ""}`.trim()}
                        rowClassName={props.rowClassName ? props.rowClassName : () => "editable-row"}
                        dataSource={props.dataSource}
                        components={components}
                        rowSelection={props.rowSelection}
                        columns={
                            props.columns
                                .filter((column: any) => {
                                    if (column.column === undefined || column.column === null) {
                                        column.column = true;
                                    }
                                    return column.column;
                                })
                                .map((col) => {
                                    if (!col.editable) {
                                        return col;
                                    }
                                    return {
                                        ...col,
                                        onCell: (record: any) => ({
                                            record,
                                            editable: col.editable,
                                            dataIndex: col.dataIndex,
                                            title: col.title,
                                            handleSave: col.handleSave,
                                            component: col.component,
                                            action: col.action,
                                        }),
                                    };
                                }) as ColumnTypes
                        }
                        loading={props.isLoading}
                        // pagination={{
                        //     total: props.totalCount,
                        //     showSizeChanger: false,
                        //     showQuickJumper: false,
                        //     showTotal: (total) => `Total ${total} items`,
                        //     ...props.pagination,
                        // }}
                        pagination={false}
                        onChange={props.onChange}
                    />
                </>
            ),
        },
        {
            key: "JEEP2",
            label: "JEEP2",
            children: (
                <>
                    {" "}
                    <Table
                        rowKey="id"
                        className={`holotrak-table ${props.tableClassName ? props.tableClassName : ""}`.trim()}
                        rowClassName={props.rowClassName ? props.rowClassName : () => "editable-row"}
                        dataSource={props.dataSource}
                        components={components}
                        rowSelection={props.rowSelection}
                        columns={
                            props.columns
                                .filter((column: any) => {
                                    if (column.column === undefined || column.column === null) {
                                        column.column = true;
                                    }
                                    return column.column;
                                })
                                .map((col) => {
                                    if (!col.editable) {
                                        return col;
                                    }
                                    return {
                                        ...col,
                                        onCell: (record: any) => ({
                                            record,
                                            editable: col.editable,
                                            dataIndex: col.dataIndex,
                                            title: col.title,
                                            handleSave: col.handleSave,
                                            component: col.component,
                                            action: col.action,
                                        }),
                                    };
                                }) as ColumnTypes
                        }
                        loading={props.isLoading}
                        // pagination={{
                        //     total: props.totalCount,
                        //     showSizeChanger: false,
                        //     showQuickJumper: false,
                        //     showTotal: (total) => `Total ${total} items`,
                        //     ...props.pagination,
                        // }}
                        pagination={false}
                        onChange={props.onChange}
                    />
                </>
            ),
        },
        {
            key: "JEEP3",
            label: "JEEP3",
            children: (
                <>
                    {" "}
                    <Table
                        rowKey="id"
                        className={`holotrak-table ${props.tableClassName ? props.tableClassName : ""}`.trim()}
                        rowClassName={props.rowClassName ? props.rowClassName : () => "editable-row"}
                        dataSource={props.dataSource}
                        components={components}
                        rowSelection={props.rowSelection}
                        columns={
                            props.columns
                                .filter((column: any) => {
                                    if (column.column === undefined || column.column === null) {
                                        column.column = true;
                                    }
                                    return column.column;
                                })
                                .map((col) => {
                                    if (!col.editable) {
                                        return col;
                                    }
                                    return {
                                        ...col,
                                        onCell: (record: any) => ({
                                            record,
                                            editable: col.editable,
                                            dataIndex: col.dataIndex,
                                            title: col.title,
                                            handleSave: col.handleSave,
                                            component: col.component,
                                            action: col.action,
                                        }),
                                    };
                                }) as ColumnTypes
                        }
                        loading={props.isLoading}
                        // pagination={{
                        //     total: props.totalCount,
                        //     showSizeChanger: false,
                        //     showQuickJumper: false,
                        //     showTotal: (total) => `Total ${total} items`,
                        //     ...props.pagination,
                        // }}
                        pagination={false}
                        onChange={props.onChange}
                    />
                </>
            ),
        },
    ];

    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    console.log("activemenu", activeIcon);
    return (
        <>
            {showHelmetTitle && (
                <Helmet>
                    <title>{props.title}</title>
                    <meta
                        name="description"
                        content={props.subTitle ? props.subTitle : `Holotrak List ${props.title}`}
                    />
                </Helmet>
            )}

            <AccessControl
                resource={props.resource}
                permissionNeeded={EPermission.READ}
                render={() => (
                    <>
                        {showHeader && (
                            <Header
                                className="holotrak-page-header"
                                style={{ background: colorBgContainer }}
                                ref={headerRef}
                            >
                                <Title className="holotrak-page-title" level={3}>
                                    <span
                                        style={{ display: "flex", columnGap: "5px", fontSize: "18px" }}
                                        onClick={() => navigate("/maintenance")}
                                    >
                                        {props.resource === "Maintenance-log" && <IBack />} {props.title}
                                    </span>
                                </Title>

                                {showTotal ? (
                                    <Badge
                                        overflowCount={props.totalCount}
                                        count={props.totalCount}
                                        style={{
                                            backgroundColor: "#dfe3ef",
                                            color: "#000",
                                            borderRadius: "18px",
                                            height: "22px",
                                            paddingTop: "2px",
                                            paddingInline: "14px",
                                        }}
                                    />
                                ) : null}

                                {props.beforeExportSlot}

                                {props.resource === "maintenance" && (
                                    <>
                                        <Button
                                            size="small"
                                            shape="round"
                                            className="export-activity export-activity--container"
                                            onClick={handleOpenModal}
                                        >
                                            Add Schedule
                                        </Button>

                                        <Button
                                            size="small"
                                            shape="round"
                                            className="export-activity export-activity--container"
                                            onClick={handleAddLogOpenModal}
                                        >
                                            Add Maintenance Log
                                        </Button>
                                        <Button
                                            size="small"
                                            shape="round"
                                            className="export-activity export-activity--container"
                                            onClick={() => navigate("/maintenance-log")}
                                        >
                                            Maintenance Log
                                        </Button>
                                    </>
                                )}
                                {hasGridView && (
                                    <Button
                                        className="holotrak-table-grid-action-wrapper"
                                        title="Toggle Grid View"
                                        shape="circle"
                                        type="default"
                                        onClick={toggleGridView}
                                    >
                                        <img src={isGridView ? iconListView : iconGridView} alt="Icon Grid View" />
                                    </Button>
                                )}
                                <div className="holotrak-table--search-controls search-device-activity">
                                    {props.resource === "maintenance" && (
                                        <>
                                            <div
                                                style={{ marginTop: "20px" }}
                                                onClick={() => props.setIsModalVisible("schedule")}
                                            >
                                                {props.isModalVisible === "schedule" ? (
                                                    <IconMaintenanceMenu />
                                                ) : (
                                                    <IconMaintenanceMenu2 />
                                                )}
                                            </div>

                                            {/* Calendar Icon */}
                                            <div
                                                style={{ marginTop: "20px" }}
                                                onClick={() => props.setIsModalVisible("calender")}
                                            >
                                                {props.isModalVisible === "calender" ? (
                                                    <>
                                                        <IconMaintenanceCal2 />
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconMaintenanceCal />
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {props.resource === "Maintenance-log" && (
                                        <>
                                            {hasGridView && (
                                                <Button
                                                    className="holotrak-table-grid-action-wrapper"
                                                    title="Toggle Grid View"
                                                    shape="circle"
                                                    type="default"
                                                    onClick={toggleGridView}
                                                >
                                                    <img
                                                        src={isGridView ? iconListView : iconGridView}
                                                        alt="Icon Grid View"
                                                    />
                                                </Button>
                                            )}
                                            <div className="holotrak-table--search-controls search-device-activity">
                                                Form
                                                <input
                                                    type="date"
                                                    id="form"
                                                    name="form"
                                                    className="select-assets"
                                                    style={{ height: 30, width: 140 }}
                                                />
                                                To
                                                <input
                                                    type="date"
                                                    id="to"
                                                    name="to"
                                                    className="select-assets"
                                                    style={{ height: 30, width: 140 }}
                                                />
                                                <select
                                                    name="cars"
                                                    id="cars"
                                                    form="carform"
                                                    className="select-assets"
                                                    style={{ height: 30, width: 200 }}
                                                >
                                                    <option value="">Select Assets</option>
                                                    <option value="assets1">Assets1</option>
                                                    <option value="assets2">Assets2</option>
                                                </select>
                                            </div>
                                        </>
                                    )}
                                    <Input.Search
                                        placeholder="Quick Search"
                                        onSearch={props.onSearch}
                                        allowClear
                                        style={{ height: 30, width: 259 }}
                                    />
                                    {props.additionalHeader}
                                </div>
                            </Header>
                        )}
                        {props.isModalVisible === "calender"
                            ? ""
                            : props.resource === "maintenance" && (
                                  <Header
                                      className="holotrak-page-header"
                                      style={{ background: colorBgContainer }}
                                      ref={headerRef}
                                  >
                                      {props.resource === "maintenance" && (
                                          <>
                                              <Button
                                                  size="small"
                                                  shape="round"
                                                  className="export-activity export-activity--container"
                                                  onClick={() => props.setIsModalVisible("overdue")}
                                                  style={{
                                                      backgroundColor:
                                                          props.isModalVisible === "overdue" ? "#9CE8D7" : undefined,
                                                  }} // Inline style for active state
                                              >
                                                  Overdue
                                              </Button>

                                              <Button
                                                  size="small"
                                                  shape="round"
                                                  className="export-activity export-activity--container"
                                                  onClick={() => props.setIsModalVisible("schedule")}
                                                  style={{
                                                      backgroundColor:
                                                          props.isModalVisible === "schedule" ? "#9CE8D7" : undefined,
                                                  }}
                                              >
                                                  Schedule
                                              </Button>
                                          </>
                                      )}
                                      {hasGridView && (
                                          <Button
                                              className="holotrak-table-grid-action-wrapper"
                                              title="Toggle Grid View"
                                              shape="circle"
                                              type="default"
                                              onClick={toggleGridView}
                                          >
                                              <img
                                                  src={isGridView ? iconListView : iconGridView}
                                                  alt="Icon Grid View"
                                              />
                                          </Button>
                                      )}
                                      <div className="holotrak-table--search-controls search-device-activity">
                                          Form
                                          <input
                                              type="date"
                                              id="form"
                                              name="form"
                                              className="select-assets"
                                              style={{ height: 30, width: 140 }}
                                          />
                                          To
                                          <input
                                              type="date"
                                              id="to"
                                              name="to"
                                              className="select-assets"
                                              style={{ height: 30, width: 140 }}
                                          />
                                          <select
                                              name="cars"
                                              id="cars"
                                              form="carform"
                                              className="select-assets"
                                              style={{ height: 30, width: 200 }}
                                          >
                                              <option value="">Select Assets</option>
                                              <option value="assets1">Assets1</option>
                                              <option value="assets2">Assets2</option>
                                          </select>
                                      </div>
                                  </Header>
                              )}

                        <Content style={contentStyle} className={"holotrak-paginated-list--content"}>
                            {props.beforeContent}
                            <Row>
                                <Col span={24}>
                                    <Spin spinning={props.isLoading && !props.dataSource}>
                                        {props.dataSource && (
                                            <>
                                                {isGridView ? (
                                                    <TableGrid
                                                        resource={props.resource}
                                                        dataSource={props.dataSource}
                                                        columns={props.columns}
                                                        gridConfig={props.gridConfig}
                                                        title={props.title}
                                                    />
                                                ) : (
                                                    <>
                                                        {props.isModalVisible === "calender" ? (
                                                            <MaintenanceCalender />
                                                        ) : props.resource === "maintenance" ? (
                                                            <Table
                                                                rowKey="id"
                                                                className={`holotrak-table ${
                                                                    props.tableClassName ? props.tableClassName : ""
                                                                }`.trim()}
                                                                rowClassName={
                                                                    props.rowClassName
                                                                        ? props.rowClassName
                                                                        : () => "editable-row"
                                                                }
                                                                dataSource={props.dataSource}
                                                                components={components}
                                                                rowSelection={props.rowSelection}
                                                                columns={
                                                                    props.columns
                                                                        .filter((column: any) => {
                                                                            if (
                                                                                column.column === undefined ||
                                                                                column.column === null
                                                                            ) {
                                                                                column.column = true;
                                                                            }
                                                                            return column.column;
                                                                        })
                                                                        .map((col) => {
                                                                            if (!col.editable) {
                                                                                return col;
                                                                            }
                                                                            return {
                                                                                ...col,
                                                                                onCell: (record: any) => ({
                                                                                    record,
                                                                                    editable: col.editable,
                                                                                    dataIndex: col.dataIndex,
                                                                                    title: col.title,
                                                                                    handleSave: col.handleSave,
                                                                                    component: col.component,
                                                                                    action: col.action,
                                                                                }),
                                                                            };
                                                                        }) as ColumnTypes
                                                                }
                                                                loading={props.isLoading}
                                                                // pagination={{
                                                                //     total: props.totalCount,
                                                                //     showSizeChanger: false,
                                                                //     showQuickJumper: false,
                                                                //     showTotal: (total) => `Total ${total} items`,
                                                                //     ...props.pagination,
                                                                // }}
                                                                pagination={false}
                                                                onChange={props.onChange}
                                                            />
                                                        ) : props.resource === "Maintenance-log" ? (
                                                            <Collapse
                                                                items={items}
                                                                defaultActiveKey={["1"]}
                                                                onChange={onChange}
                                                                expandIconPosition="right"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Spin>
                                </Col>
                            </Row>
                        </Content>
                        <Footer
                            ref={footerRef}
                            className={`footer-paginated-list footer-paginated-list--${props.title.toLowerCase()}`}
                        >
                            {props.afterContent}
                        </Footer>

                        <Modal
                            open={isAddSchedule}
                            title={
                                <Title
                                    level={4}
                                    className="add-title"
                                    style={{ marginBottom: "25px", fontWeight: 400 }}
                                >
                                    Add Schedule
                                </Title>
                            }
                            onCancel={handleCloseModal}
                            footer={null}
                            width={475}
                            // height={670}
                        >
                            <AddSchedule onCancel={handleCloseModal} />
                        </Modal>
                        <Modal
                            open={isAddMaintenanceLog}
                            title={
                                <Title level={4} style={{ marginBottom: "25px", fontWeight: 400 }}>
                                    Add Maintenance Log
                                </Title>
                            }
                            onCancel={handleAddLogCloseModal}
                            footer={null}
                            width={475}
                            // height={670}
                        >
                            <AddMaintenanceLog onCancel={handleAddLogCloseModal} />
                        </Modal>
                    </>
                )}
            />
        </>
    );
};

export default MaintenanceList;

