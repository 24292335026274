import React from 'react';
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";
import {useUserPermissionsForRole} from "../../utils/useUserPermissionsForRole";

interface IAccessControlProps {
    resource: EResource;
    permissionNeeded: EPermission;
    render: (props: any) => React.ReactElement;
    sidebar?: Boolean;
    renderIfNoPermission?: (props: any) => React.ReactElement;
}

const AccessControl: React.FC<IAccessControlProps> = (props) => {
    const {access} = useUserPermissionsForRole(props.resource, props.permissionNeeded);

    const newProps = {...props};
    delete newProps.permissionNeeded;
    delete newProps.renderIfNoPermission;
    delete newProps.resource;


    if (!access) {
        if (props.renderIfNoPermission) {
            return props.renderIfNoPermission(newProps);
        } else if (props.sidebar === true) {
            return <> </>;
        }
        return (
            <div className="report-data-blank">
                <h2>Access Denied</h2>
            </div>
        );
    }

    return props.render(newProps);
};

export default AccessControl;
