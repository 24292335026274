import {IHasTags} from "contracts/IHasTags";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import dayjs from "dayjs";
import {ESensorType} from "../EDeviceStatus";


@jsonObject
export class DriverBase implements IHasTags {
    @jsonMember(String)
    name: string;
    @jsonMember(String)
    department?: string;
    @jsonMember(String)
    organisationId?: string;
    @jsonMember(String)
    tags?: string;

    @jsonMember(String)
    issuingAuthority?: string;
    @jsonMember(Number)
    driverLicenceNumber?: number;
    @jsonMember(String)
    licenceClass?: string;
    @jsonMember(Number)
    phone?: number;
    @jsonMember(String)
    email?: string;
    @jsonMember(String)
    location?: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    expirationDate?: dayjs.Dayjs;

    @jsonMember(String)
    driverProfile?: string;
    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(String)
    id: string;
    sensorType: ESensorType = ESensorType.VEHICLE;



    get uiHeight(): number {
        return 84;
    }
}

export const DriverBaseSerializer = new TypedJSON(DriverBase);
