import {Typography} from "antd";
import React, {useMemo} from 'react';
import useTablePagination from "utils/useTablePagination";
import {DateTimeRenderer} from "../../components/datetime";
import PaginatedList, {HolotrakColumnConfig} from "../../components/paginated-list";

import {EResource} from "../../contracts/EResource";
import {useGetMessagesListQuery} from "../../redux/services/losant/messages";

const {Paragraph} = Typography;

export const Messages: React.FC = () => {
    const {
        pagination,
        searchTerm,
        handleTableChange,
        handleSearch,
        sorting,
    } = useTablePagination({
        pageSize: 30,
    }, {
        order: 'descend',
        field: 'createdAt',
    });

    const {isLoading, data: tableResponse} = useGetMessagesListQuery({
        page: pagination.current,
        limit: pagination.pageSize,
        fieldValue: 'deviceName', // Perhaps we can change it to better
        searchValue: searchTerm,
        sort: sorting.order,
    });


    const columns = useMemo<HolotrakColumnConfig>(() => ([
        {
            title: 'Received',
            key: 'createdAt',
            width: 220,
            render: (item) => <DateTimeRenderer date={item}/>,
            dataIndex: 'createdAt',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Message',
            key: 'message',
            dataIndex: 'message',
            render: (item, record) => (
                <Paragraph ellipsis={{rows: 2, expandable: true, symbol: 'more'}}>
                    {item}
                </Paragraph>
            ),
        },
    ]), []);

    return (
        <PaginatedList
            title="Messages"
            resource={EResource.MESSAGES}
            onSearch={handleSearch}
            onChange={handleTableChange}
            pagination={pagination}
            isLoading={isLoading}
            dataSource={tableResponse?.items}
            columns={columns}
            totalCount={tableResponse?.totalCount}
        />
    );
};

export default Messages;
