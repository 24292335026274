import {losantApi} from "./index";
import {DeviceSettings, IDeviceSettingsRequest} from "../../../contracts/holotrak/deviceSettings";
import {DeviceSettingsResponse} from "../../../contracts/holotrak/deviceSettingsResponse";
import {TAG_DEVICE_SETTINGS} from "../../../contracts/reduxResourceTags";
import {FrequentlyAskedQuestionListResponse} from "../../../contracts/holotrak/frequentQuestion";


const settingsApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceSettings: builder.query<DeviceSettings, string>({
            providesTags: (result, error, deviceId) => {
                return [{type: TAG_DEVICE_SETTINGS, id: deviceId}];
            },
            query: (deviceId) => {
                return {
                    url: `/device-settings/${deviceId}`,
                    method: "get",
                };
            },

            transformResponse: (response: DeviceSettingsResponse) => {
                return response.items[0];
            },
        }),
        setDeviceSettings: builder.mutation<DeviceSettings, IDeviceSettingsRequest>({
            invalidatesTags: (response, error, query) => {
                return [{type: TAG_DEVICE_SETTINGS, id: query.deviceId}];
            },
            query: (settingsRequest: IDeviceSettingsRequest) => {
                return {
                    url: `/device-settings/${settingsRequest.deviceId}`,
                    method: "put",
                    body: settingsRequest.settings,
                };
            }
        }),
        getFrequentQuestions: builder.query<FrequentlyAskedQuestionListResponse, void>({
            query: () => {
                return {
                    url: `/admin/faq`,
                    method: "get",
                };
            }
        })
    })
});

export const {
    useGetDeviceSettingsQuery,
    useSetDeviceSettingsMutation,
    useGetFrequentQuestionsQuery
} = settingsApi;
