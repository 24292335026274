import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {LosantPagination} from "./losantPagination";
import dayjs from "dayjs";


@jsonObject()
export class DeviceSubscription {
    @jsonMember(String)
    deviceId: string;

    @jsonMember(String)
    planId: string;

    @jsonMember(Number, {
        deserializer: (value: any) => {
            return Number(value);
        }
    })
    actualLocatePoints: number;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    expiringAt: dayjs.Dayjs;

    @jsonMember(String)
    id: string;

    @jsonMember(Boolean)
    isActive: boolean;

    @jsonMember(Boolean)
    isDeleted: boolean;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

}

@jsonObject()
export class DeviceSubscriptionListResponse {
    @jsonArrayMember(DeviceSubscription)
    subscriptions: DeviceSubscription[] = [];

    @jsonMember(LosantPagination)
    query: LosantPagination;
}


export interface IDeviceSubscriptionQuery {
    devices?: string[];
    page?: number;
}

export const DeviceSubscriptionListResponseSerializer = new TypedJSON(DeviceSubscriptionListResponse);
export const DeviceSubscriptionSerializer = new TypedJSON(DeviceSubscription);
