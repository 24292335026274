import {Button, message, Popconfirm, Space, Tooltip} from "antd";
import {IconFilter} from "assets/icons/icon-filter";
import iconDelete from "assets/svg/icon-delete.svg";
import iconEdit from "assets/svg/icon-edit.svg";
import AccessControl from "components/core/AccessControl";
import {DateTimeRenderer} from "components/datetime";
import {QuickDeviceInfo} from "components/detail-rows/QuickDeviceInfo";
import {SensorModelName} from "components/device-sensor-details/SensorModelName";
import PaginatedList, {HolotrakColumnConfig} from "components/paginated-list";
import ColumnConfigurator from "components/paginated-list/ColumnConfigurator";
import DeviceSensorForm from "components/sensor-form";
import {TemperatureRenderer} from "components/temperature-renderer";
import {EGroupType, getRouteForGroupType} from "contracts/EGroupType";
import {EPermission} from "contracts/EPermission";
import {EResource} from "contracts/EResource";
import {ETemperatureUnit} from "contracts/ETemperatureUnit";
import {Device, DeviceSerializer} from "contracts/holotrak/device";
import {DeviceBase} from "contracts/holotrak/deviceBase";
import {SensorBase, SensorBaseSerializer} from "contracts/holotrak/sensorBase";
import dayjs from "dayjs";
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useGetDevicesQuery, useSyncDevicesMutation} from "redux/services/express";
import {useGetSensorExpiryReportQuery} from "redux/services/express/reports";
import {useDeleteSensorMutation} from "redux/services/losant/sensors";
import useTablePagination from "utils/useTablePagination";
import useToggleValue from "utils/useToggleValue";


export const SensorReport: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const {isLoading: isLoadingDevices} = useGetDevicesQuery();

    const {
        searchTerm,
        handleSearch,
        pagination,
        handleTableChange,
    } = useTablePagination({
        pageSize: 30
    });

    const [isSensorFormOpen, setIsSensorFormOpen] = useState<boolean>(false);
    const [currentSensor, setCurrentSensor] = useState<SensorBase>(null);

    const navigate = useNavigate();

    const [syncDevices, {isLoading: isSyncingItems}] = useSyncDevicesMutation();


    const {isLoading, data: tableResponse, refetch} = useGetSensorExpiryReportQuery(null, {
        selectFromResult: ({isLoading, data}) => {
            return {
                data: {
                    totalCount: data?.totalCount ?? 0,
                    items: data?.items?.map((item) => SensorBaseSerializer.parse(item)) || [],
                },
                isLoading
            };
        },
    });

    const records = useMemo(() => {
        return isLoading ? [] : tableResponse.items.filter((item) => {
            return (
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.mobileId.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    }, [isLoading, searchTerm, tableResponse.items]);

    const [isFilterOpen, toggleFilterOpen] = useToggleValue(false);


    const addNewSensor = useCallback(() => {
        setCurrentSensor(null);
        setIsSensorFormOpen(true);
    }, [setCurrentSensor, setIsSensorFormOpen]);

    const closeDeviceSensorForm = useCallback(() => {
        setIsSensorFormOpen(false);
        setCurrentSensor(null);
    }, [setIsSensorFormOpen, setCurrentSensor]);


    const [deleteSensor] = useDeleteSensorMutation();

    const handleSensorDeleteClicked = useCallback((sensor: SensorBase) => {
        const hideDeleteMessage = messageApi.loading("Deleting Sensor...", 0);

        deleteSensor(sensor.id).then(() => {
            syncDevices([sensor]).then(() => {
                refetch();
                hideDeleteMessage();
                messageApi.open({
                    type: "success",
                    content: "Sensor deleted successfully!",
                });
            });
        });
    }, [refetch, deleteSensor, messageApi, syncDevices]);


    const cancelDelete = useCallback(() => {
        return messageApi.open({
            type: "error",
            content: "Cancelled",
        });
    }, [messageApi]);

    const [columns, setColumns] = useState<HolotrakColumnConfig>([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            column: true,
            sorter: (a: SensorBase, b: SensorBase) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
            showSorterTooltip: true,
        },
        {
            title: 'Device',
            dataIndex: 'linkedDevice',
            key: 'linkedDevice',
            column: true,
            render: (item, record: SensorBase) => <QuickDeviceInfo id={record.getTagValue('linkedDevice')}/>,
            sortDirections: ['ascend', 'descend'],
            showSorterTooltip: true
        },

        {
            title: 'MAC',
            dataIndex: 'mobileId',
            key: 'mobileId',
            column: true,
            render: (item, record: SensorBase) => record.mobileId,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            column: true,
            render: (item, record) => record.getTagValue('type')
        },
        {
            title: 'Sensor Model',
            dataIndex: 'sensorModel',
            key: 'sensorModel',
            column: true,
            render: (item, record) => (
                <SensorModelName model={record.sensorModel}/>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            column: true,
            render: value => value || '',
        },
        {
            title: 'Temperature',
            dataIndex: 'temperature',
            key: 'temperature',
            column: true,
            render: (item, record: SensorBase) => (
                <TemperatureRenderer
                    value={record.getCompositeStateItem("temperature").getFixedValue(2, undefined)}
                    inputUnit={ETemperatureUnit.Celsius}
                />
            ),
        },
        {
            title: 'Humidity',
            dataIndex: 'humidity',
            key: 'humidity',
            column: true,
            render: (item, record: SensorBase) => record.getCompositeValue("humidity", "%"),
        },
        {
            title: 'Battery',
            dataIndex: 'battery_level',
            key: 'battery_level',
            column: true,
            render: (item, record: SensorBase) => (record.getCompositeValue("battery_level", "%") == "- %" ? "-" : record.getCompositeValue("battery_level", "%")),
        },
        {
            title: 'External Voltage',
            dataIndex: 'voltage_level',
            key: 'voltage_level',
            column: true,
            render: (item, record: SensorBase) => {
                return record.getCompositeValue("voltage_level", "V");
            },
        },
        {
            title: 'Door Status',
            dataIndex: 'is_door_open',
            key: 'is_door_open',
            column: true,
            render: (item, record: SensorBase) => {
                return record.getCompositeStateItem("is_door_open").getFiniteValue() ? 'Open' : 'Closed';
            },
        },
        {
            title: 'Last known Location',
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
            column: false,
            render: value => (value === '--' ? '' : value || ''),
        },
        {
            title: 'Last Reported At',
            dataIndex: 'compositeState.last_reported_at',
            key: 'last_reported_at',
            column: true,
            render: (value, record: SensorBase) => (
                <DateTimeRenderer date={record.getCompositeStateItem('last_reported_at').time}/>
            ),
            renderData: (item, record: SensorBase) => {
                return record.getCompositeStateItem('last_reported_at')?.time?.format('YYYY-MM-DD HH:mm:ss') ?? '';
            },
        },
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'creationDate',
            sorter: true,
            render: (item) => <DateTimeRenderer date={item} onlyDate/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            column: false,
            sorter: true,
            render: (item) => <DateTimeRenderer date={item}/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
        {
            title: "ACTIONS",
            dataIndex: "actions",
            key: "actions",
            render: (item, sensorRecord: SensorBase) => (
                <Space>
                    <AccessControl
                        resource={EResource.DEVICE_SENSORS}
                        permissionNeeded={EPermission.DELETE}
                        sidebar={true}
                        render={
                            accessProps => (
                                <Popconfirm
                                    {...accessProps}
                                    title="Delete this record?"
                                    onConfirm={() => handleSensorDeleteClicked(sensorRecord)}
                                    onCancel={cancelDelete}
                                    okText="Yes"
                                    cancelText="NO"
                                >
                                    <Tooltip title="Delete Sensor">
                                        <div style={{float: "left", cursor: "pointer"}}>
                                            <img src={iconDelete} alt="DeleteGroupIcon"/>
                                        </div>
                                    </Tooltip>
                                </Popconfirm>
                            )
                        }/>

                    <AccessControl
                        resource={EResource.VEHICLES}
                        permissionNeeded={EPermission.UPDATE}
                        render={() => (
                            <Tooltip title="Edit Device Sensor">
                                <div style={{float: "left", cursor: "pointer"}}>
                                    <img
                                        src={iconEdit}
                                        alt="EditGroupIcon"
                                        onClick={() => {
                                            setCurrentSensor(sensorRecord);
                                            setIsSensorFormOpen(true);
                                        }}/>
                                </div>
                            </Tooltip>
                        )}
                    />
                </Space>
            ),
        },
    ]);


    const filterSupport = useMemo(() => {
        return (
            <div className="filter-support">
                <Button type='default' shape='circle' icon={<IconFilter/>} onClick={toggleFilterOpen}/>
            </div>
        );
    }, [toggleFilterOpen]);

    const renderGridContent = useCallback((item: SensorBase) => {
        return !item ? null : (
            <ul>
                <li>
                    <h4>Name</h4>
                    <p>{item.name ?? '-'}</p>
                </li>
                <li>
                    <h4>MAC</h4>
                    <p>{item.getTagValue('mobileId') ?? '-'}</p>
                </li>
                <li>
                    <h4>Location</h4>
                    <p>{item.additionalInfo ?? '-'}</p>
                </li>
            </ul>
        );
    }, []);

    return (
        <>
            <PaginatedList
                title="Sensors"
                resource={EResource.DEVICE_SENSORS}
                onSearch={handleSearch}
                pagination={pagination}
                onChange={handleTableChange}
                isLoading={isLoading}
                dataSource={records}
                columns={columns}
                additionalHeader={filterSupport}
                totalCount={tableResponse?.totalCount}
                showTotal={true}
                beforeExportSlot={(
                    <Button shape='round' onClick={addNewSensor} className='add-new-driver'>
                        Add New Sensor
                    </Button>
                )}
                afterContent={(
                    <div className='bottom-bar'>
                        <Space size={24}>
                            <Button
                                size='small'
                                onClick={() => {
                                    navigate(`/${getRouteForGroupType(EGroupType.DEVICE_SENSOR)}`);
                                }}
                            >
                                Map
                            </Button>
                        </Space>
                    </div>
                )}

                hasGridView={true}
                gridConfig={{
                    title: (item: SensorBase) => item?.name,
                    gridContent: (item: SensorBase) => renderGridContent(item)
                }}

            />
            <ColumnConfigurator
                isConfiguring={isFilterOpen}
                toggleConfigurationMode={toggleFilterOpen}
                columns={columns}
                setColumns={setColumns}
            />

            <DeviceSensorForm
                isOpen={isSensorFormOpen}
                onClose={closeDeviceSensorForm}
                sensor={currentSensor}
            />

            {contextHolder}
        </>
    );
};

export default SensorReport;
