import {TripHistory} from "./tripHistory";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import {LatLng, LatLngBounds} from "leaflet";
import {IRenderableTrip} from "../IRenderableTrip";

// Required to ensure that dayjs is included in the bundle
import dayjs from "dayjs";


@jsonObject()
class NamedItem {
    @jsonMember(String)
    id: string;

    @jsonMember(String)
    name: string;
}

@jsonObject()
export class Trip {
    @jsonMember(String)
    id: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(String)
    device: string;

    @jsonMember(String)
    organisation: string;


    @jsonMember(String)
    status: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    start_time: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    end_time: dayjs.Dayjs;

    @jsonMember(String)
    end_location: string;

    @jsonMember(String)
    start_location: string;

    @jsonMember(Number)
    duration: number;

    @jsonMember(Number)
    distance: number;

    @jsonMember(Number)
    dateDiff: number;

    @jsonMember(Number)
    rangeEnd: number;

    @jsonMember(String)
    address: string;

    @jsonMember(TripHistory)
    tripHistory: TripHistory;

    // TODO: Add these back in when we have the deserializer info
    @jsonMember(NamedItem)
    driver: NamedItem;

    // TODO: Add these back in when we have the deserializer info
    @jsonMember(NamedItem)
    vehicle: NamedItem;

    get statusText(): string {
        switch (this.status.toLowerCase()) {
            case "open":
                return "In Progress";

            case "closed":
                return "Destination";

            default:
                return "-";
        }
    }

    get isComplete(): boolean {
        return this.status.toLowerCase() === "closed";
    }

    get addressStart(): string {
        if (this.tripHistory?.data?.points?.length > 0) {
            return this.tripHistory.data.points[0].data.address;
        }

        return "Unknown Location";
    }

    get addressEnd(): string {
        if (this.tripHistory?.data?.points?.length > 0) {
            return this.tripHistory.data.points[this.tripHistory.data.points.length - 1].data.address;
        }

        return "Unknown Location";
    }

    get start(): LatLng {
        const [lat, lng] = this.start_location.split(",").map((x) => parseFloat(x));
        return new LatLng(lat, lng);
    }

    get end(): LatLng {
        const [lat, lng] = this.end_location.split(",").map((x) => parseFloat(x));
        return new LatLng(lat, lng);
    }

    get points(): LatLng[] {
        return this.tripHistory?.data?.points?.map((x) => new LatLng(x.data.latitude, x.data.longitude)) || [];
    }

    get bounds(): LatLngBounds | null {
        let bounds = null;
        if (this.points.length > 0) {
            bounds = new LatLngBounds(this.points[0], this.points[0]);
            this.points.forEach((point) => {
                bounds.extend(point);
            });
        }

        return bounds;
    }

    get totalDuration(): string {
        let diffTime = 0;
        const date1 = this.start_time;
        const date2 = (this.end_time) ? this.end_time : dayjs();

        diffTime = date2.diff(date1);
        let showDiff = '-';
        const seconds = (diffTime / 1000);
        const mint = (diffTime / (1000 * 60));
        const hours = (diffTime / (1000 * 60 * 60));
        const days = (diffTime / (1000 * 60 * 60 * 24));
        if (seconds < 60) {
            showDiff = `${seconds.toFixed(0)} Sec`;
        } else if (mint < 60) {
            showDiff = `${mint.toFixed(0)} Min`;
        } else if (hours < 24) {
            const mn = (hours % 1) * 60;

            showDiff = `${hours.toFixed(0)} Hrs ${mn.toFixed(0)} Min`;
        } else {
            const hr = ((days % 1) * 24);
            const mn = (hr % 1) * 60;

            showDiff = `${days.toFixed(0)} Days ${hr.toFixed(0)} Hrs ${mn.toFixed(0)} Min `;
        }

        return showDiff;
    }

    getName(type: string) {
        let obj: NamedItem = null;
        switch (type) {
            case "driver":
                obj = this.driver;
                break;

            case "vehicle":
                obj = this.vehicle;
                break;

            default:
                break;
        }

        return obj?.name || "NA";
    }

    getRenderableTrip(): IRenderableTrip {
        return {
            id: this.id,
            points: this.points,
            start: this.start,
            end: this.end,
            bounds: this.bounds,
        };
    }
}

export const tripSerializer = new TypedJSON(Trip);
