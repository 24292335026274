import React from 'react';
import {Form, Select, Space} from "antd";
import {IAlertSaveRequest} from "../../contracts/holotrak/alerts";
import {getVehicleTypeByValue, VEHICLE_TYPES} from "./VEHICLE_TYPES";

interface IVehicleTypeSelectorProps {
    typeField: string;
}

const VehicleTypeSelector: React.FC<IVehicleTypeSelectorProps> = ({typeField}) => {

    const form = Form.useFormInstance<IAlertSaveRequest>();
    const vehicleType = getVehicleTypeByValue(form.getFieldValue(typeField));

    return (
        <Space
            direction={'vertical'}
            size={'large'}
        >

            <Form.Item
                label='Vehicle Type'
                name="type"
                rules={[
                    {
                        required: true,
                        message: "",
                    },
                ]}
            >
                <Select
                    allowClear
                    placeholder="Select Vehicle Type"
                    onChange={() => {
                        form.setFieldsValue({
                            subType: undefined,
                        });
                    }}
                    showSearch
                >
                    {VEHICLE_TYPES.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {
                vehicleType?.subTypes && (
                    <Form.Item
                        label='Vehicle Sub Type'
                        name="subType"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Vehicle Sub Type"
                        >
                            {vehicleType.subTypes.map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )
            }
        </Space>
    );
}


export default VehicleTypeSelector;
