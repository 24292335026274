import { losantApi } from "./index";
import { createSelector } from "@reduxjs/toolkit";
import { IVehicleSaveRequest, Vehicle, VehiclePaginatedList, VehicleSerializer } from "contracts/holotrak/vehicle";
import { selectVehicleFilters } from "../../slices/app";
import { ILosantListQuery } from "../../../contracts/ILosantListQuery";
import { TAG_DEVICE, TAG_DRIVER, TAG_VEHICLE } from "../../../contracts/reduxResourceTags";
import { selectAllDevices } from "../express";
import { DeviceSerializer } from "../../../contracts/holotrak/device";

const vehiclesApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getVehicles: builder.query<VehiclePaginatedList, void>({
            providesTags: (result) => {
                return [
                    ...result.items.map(({ id }) => ({ type: TAG_VEHICLE, id })),
                    { type: TAG_VEHICLE, id: "LIST" },
                ];
            },
            query: () => {
                return {
                    url: "/vehicles?sortField=name&sortDirection=asc",
                    method: "get",
                };
            },
        }),

        getPaginatedVehiclesList: builder.query<VehiclePaginatedList, ILosantListQuery>({
            providesTags: (result) => {
                return [
                    ...result.items.map(({ id }) => ({ type: TAG_VEHICLE, id })),
                    { type: TAG_VEHICLE, id: "LIST" },
                ];
            },
            query: (queryArgs) => {
                const paginationQuery = `${queryArgs.page}&pageSize=${queryArgs.limit}`;
                const searchQuery = queryArgs.searchValue
                    ? `&filterField=name&filterValue=${queryArgs.searchValue}`
                    : "";
                const sortQuery =
                    queryArgs.sort && queryArgs.sortBy
                        ? `&sortField=${queryArgs.sortBy}&sortDirection=${queryArgs.sort === "ascend" ? "asc" : "desc"}`
                        : "";

                return {
                    url: `/vehicles?page=${paginationQuery}${searchQuery}${sortQuery}`,
                    method: "get",
                };
            },
        }),

        getVehicleDetails: builder.query<Vehicle, string>({
            query: (vehicleId) => {
                return {
                    url: `/vehicle/${vehicleId}`,
                    method: "get",
                };
            },
            transformResponse: (response: any) => {
                return response?.items[0];
            },
        }),

        updateVehicle: builder.mutation<Vehicle, IVehicleSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [
                    {
                        type: TAG_DEVICE,
                        id: arg.data.device.id,
                    },
                    {
                        type: TAG_DRIVER,
                        id: "LIST",
                    },
                    {
                        type: TAG_VEHICLE,
                        id: "LIST",
                    },
                    {
                        type: TAG_VEHICLE,
                        id: arg.data.id,
                    },
                ];
            },
            query: (vehicleBody) => {
                let vehicleData = {
                    ...vehicleBody.data,
                    driver: vehicleBody.data.driver?.id,
                    device: vehicleBody.data.device?.id,
                };

                if (!vehicleBody.data.driver?.id) {
                    vehicleData.driver = "";
                }

                return {
                    url: `/vehicles/${vehicleBody.data.id}`,
                    method: "put",
                    body: {
                        data: vehicleData,
                    },
                };
            },
        }),

        createVehicle: builder.mutation<Vehicle, IVehicleSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [
                    {
                        type: TAG_DRIVER,
                        id: "LIST",
                    },
                    {
                        type: TAG_VEHICLE,
                        id: "LIST",
                    },
                    {
                        type: TAG_VEHICLE,
                        id: arg.data.id,
                    },
                ];
            },
            query: (vehicleBody) => {
                return {
                    url: `/vehicle`,
                    method: "post",
                    body: {
                        data: {
                            ...vehicleBody.data,
                            driver: vehicleBody.data.driver?.id,
                            device: vehicleBody.data.device?.id,
                        },
                    },
                };
            },
        }),

        deleteVehicle: builder.mutation<any, string>({
            invalidatesTags: (result, error, vehicleId) => {
                return [
                    {
                        type: TAG_VEHICLE,
                        id: "LIST",
                    },
                    {
                        type: TAG_VEHICLE,
                        id: vehicleId,
                    },
                ];
            },
            query: (vehicleId) => {
                return {
                    url: `/vehicles/${vehicleId}`,
                    method: "delete",
                };
            },
        }),
    }),
});

export const {
    useGetVehiclesQuery,
    useGetPaginatedVehiclesListQuery,
    useGetVehicleDetailsQuery,
    useUpdateVehicleMutation,
    useCreateVehicleMutation,
    useDeleteVehicleMutation,
} = vehiclesApi;

export const selectVehicleById = (appState, queryArgs) =>
    vehiclesApi.endpoints.getVehicleDetails.select(queryArgs)(appState);

export const selectVehiclesResult = (appState) => vehiclesApi.endpoints.getVehicles.select()(appState);

export const selectVehicles = createSelector([selectVehiclesResult, selectAllDevices], (vehicles, devices) => {
    return (
        vehicles.data?.items.map((vehicle) => {
            let vehicleData = VehicleSerializer.parse(vehicle);
            // vehicleData.device = devices.find(device => device.id === vehicleData.deviceId);
            return vehicleData;
        }) || []
    );
});

export const selectSearchedVehicle = createSelector([selectVehicles, selectVehicleFilters], (vehicles, filters) => {
    if (!filters.search) {
        return vehicles;
    }
    return (
        vehicles
            .filter((vehicle) => vehicle.name.toLowerCase().includes(filters.search.toLowerCase()))
            .map((vehicle) => VehicleSerializer.parse(vehicle)) || []
    );
});

export const selectVehicleByName = createSelector(
    [selectVehicles, (_, filter) => filter.toLowerCase()],
    (vehicles, filter) => {
        if (!filter) {
            return vehicles;
        }
        return (
            vehicles
                .filter((vehicle) => vehicle.name.toLowerCase().includes(filter))
                .map((vehicle) => VehicleSerializer.parse(vehicle)) || []
        );
    }
);

