import {Spin, Typography} from "antd";
import {convertStringToTemperatureUnit, convertTemperature, ETemperatureUnit} from "contracts/ETemperatureUnit";
import React, {useContext, useMemo} from 'react';
import {ExperienceGroup} from "../../contracts/holotrak/experienceGroup";
import {OrganisationContext} from "../../utils/organisationContext";

const {Text} = Typography;

interface ITemperatureRendererProps {
    value: string;
    inputUnit: ETemperatureUnit;
    outputUnit?: ETemperatureUnit;
    fallback?: string;
    className?: string;
}

interface IOrganizationalTemperatureRendererProps extends ITemperatureRendererProps {
    organisation: ExperienceGroup;
}

export const TemperatureRenderer: React.FC<ITemperatureRendererProps> = (props) => {
    const organisation = useContext(OrganisationContext);


    return (
        <span style={{display: 'inline-block'}}>
          <Spin spinning={!organisation}>
            {
                organisation && (
                    <OrganizationalTemperatureRenderer organisation={organisation} {...props}/>
                )
            }
          </Spin>
        </span>
    );
};

export const OrganizationalTemperatureRenderer: React.FC<IOrganizationalTemperatureRendererProps> = ({
                                                                                                         organisation,
                                                                                                         value,
                                                                                                         inputUnit,
                                                                                                         outputUnit,
                                                                                                         className,
                                                                                                         fallback = '-'
                                                                                                     }) => {

    const temperatureUnit = convertStringToTemperatureUnit(outputUnit || organisation.temperatureUnit);

    const convertedValue = useMemo(() => {
        return convertTemperature(value, inputUnit, temperatureUnit);
    }, [value, inputUnit, temperatureUnit]);

    return (
        <Text className={className}>
            {
                (value)
                    ? `${convertedValue}°${temperatureUnit}`
                    : fallback
            }
        </Text>
    );
}
