import dayjs from "dayjs";
import L, {LatLngBounds, LatLngTuple} from "leaflet";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import {DecimalHexTwosComplement} from "utils/utils";
import {IOutdoorLocatable} from "../IOutdoorLocatable";
import {DeviceSerializer} from "./device";
import {LosantPagination} from "./losantPagination";

@jsonObject
class RectangleConfig {
    ne: LatLngTuple;
    sw: LatLngTuple;


    constructor(ne: LatLngTuple, sw: LatLngTuple) {
        this.ne = ne;
        this.sw = sw;
    }
}

@jsonObject
export class Geofence {
    /**
     ```json
     {
     "name": "7 wonders of the  world",
     "shape": "<url to kml/kmz file>",
     "coordinates": null,
     "radius": null,
     "center": null,
     "organisationId": "5fbc04b4f7a5b5000636f9b8",
     "type": "kml",
     "address": "across the world",
     "notes": "7 wornders",
     "subType": "other",
     "slot": null,
     "alertOnEntry": false,
     "alertOnExit": false,
     "currentlyInside": false,
     "deviceId": null,
     "updatedAt": "2022-04-25T09:04:27.686Z",
     "createdAt": "2022-04-25T09:04:27.686Z",
     "id": "6266641b3fe66edd6926ef8a"
     }
     ```
     */

    @jsonMember(String)
    name?: string;

    @jsonMember(String)
    shape?: string;

    @jsonMember(String, {
        name: 'coordinates',
        deserializer: (value: string) => {
            return !!value ? value : null;
        }
    })
    coordinates?: string;

    @jsonMember(Number)
    radius?: number;

    @jsonMember(String)
    center?: string;

    @jsonMember(String)
    organisationId?: string;

    @jsonMember(String)
    type?: string;

    @jsonMember(String)
    subType?: string;

    @jsonMember(String)
    address?: string;

    @jsonMember(String)
    notes?: string;

    @jsonMember(String)
    slot?: string;

    @jsonMember(Boolean)
    alertOnEntry?: boolean;

    @jsonMember(Boolean)
    alertOnExit?: boolean;

    @jsonMember(Boolean)
    currentlyInside?: boolean;

    @jsonMember(String)
    deviceId?: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(String)
    id?: string;

    private _locatableItems: IOutdoorLocatable[] = [];

    get locatableItems(): IOutdoorLocatable[] {
        // ANTI-PATTERN since we are locating devices only.
        return this._locatableItems.map(
            (item) => DeviceSerializer.parse(item)
        );
    }

    set locatableItems(value: IOutdoorLocatable[]) {
        this._locatableItems = value;
    }

    get rectangleConfig(): RectangleConfig {
        try {
            const [latA, lonA, latB, lonB] = JSON.parse(this.coordinates);
            return new RectangleConfig([latA, lonA], [latB, lonB]);
        } catch (e) {
            return null;
        }
    }

    get centralPoint(): LatLngTuple | null {
        if (!this.center) {
            return null;
        }

        const center = this.center.split(',');
        if (center.length === 2) {
            return [parseFloat(center[0]), parseFloat(center[1])];
        }

        return null;
    }

    getBounds(map: L.Map): LatLngBounds | null {
        let bounds = null;
        if (this.coordinates) {
            switch (this.shape) {
                case 'rectangle':
                    try {
                        const [latA, lonA, latB, lonB] = JSON.parse(this.coordinates);
                        bounds = L.latLngBounds([[lonA, latA], [lonB, latB]]);
                    } catch (e) {
                        console.log("Invalid Rectangle Data", e);
                    }

                    break;

                case 'circle':
                    try {
                        const center: LatLngTuple = this.centralPoint;
                        const circle = L.circle(center, {
                            radius: this.radius,
                        });

                        circle.addTo(map);
                        bounds = circle.getBounds();
                        circle.remove();
                    } catch (e) {
                        console.log("Invalid Circle Data", e);
                    }
            }
        }

        return bounds;
    }

    getGoogleDimensions(google): IGeofenceDimensions {
        const geofence = this;
        const coordinates = JSON.parse(geofence.coordinates);
        let zone = 0;
        let centerX = 0;
        let centerY = 0;
        let radius = 0;

        let rectangleConfig = geofence.rectangleConfig;

        // width
        let width: any = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(coordinates[1], coordinates[0]),
            new google.maps.LatLng(coordinates[3], coordinates[0])
        );

        // length
        let length: any = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(coordinates[3], coordinates[2]),
            new google.maps.LatLng(coordinates[3], coordinates[0])
        );

        if (geofence && geofence.shape === "circle") {
            radius = this.radius; // Add radius for circle

            zone = 1;
        } else if (geofence && geofence.shape === "rectangle") {
            zone = 0;
        }
        // @ts-ignore
        length = parseInt(length / 2);
        // @ts-ignore
        width = parseInt(width / 2);
        const center = geofence.center.split(",");
        centerY = Number(center[0].slice(0, 11));
        centerX = Number(center[1].slice(0, 12));
        // eslint-disable-next-line no-restricted-properties
        centerY = Math.round(centerY * Math.pow(10, 7));
        if (centerY <= 0) {
            const lat = DecimalHexTwosComplement(centerY);
            centerY = parseInt(lat, 16);
        }
        // eslint-disable-next-line no-restricted-properties
        centerX *= Math.pow(10, 7);

        if (centerX <= 0) {
            const ln = DecimalHexTwosComplement(centerX);
            centerX = parseInt(ln, 16);
        }

        return {
            width,
            length,
            zone,
            centerX,
            centerY,
            radius,
            rectangleConfig
        };
    }
}

export interface IGeofenceDimensions {
    width: number;
    length: number;
    zone: number;

    centerX: number;
    centerY: number;

    radius: number;

    rectangleConfig: RectangleConfig;
}


@jsonObject
export class GeofenceListResponse extends LosantPagination {
    items: Geofence[];
}

export const geofenceSerializer = new TypedJSON(Geofence);
