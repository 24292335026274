import {IGroupableItem} from "contracts/IGroupableItem";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {LosantPagination} from "./losantPagination";
import {Vehicle, VehicleSerializer} from "./vehicle";
import {DeviceBase} from "./deviceBase";
import {DriverBaseSerializer} from "./driverBase";


@jsonObject()
export class Device extends DeviceBase implements IGroupableItem {

    @jsonMember(Vehicle, {
        deserializer: (value: any) => {
            let vehicle = VehicleSerializer.parse(value);
            try {
                vehicle.driver = DriverBaseSerializer.parse(value?.driver.items[0]);
            } catch (e) {
            }
            return vehicle;
        }
    })
    vehicle: Vehicle;

    get driverName(): string {
        return this.vehicle?.driver?.name || 'N/A';
    }

    get vehicleName(): string {
        return this.vehicle?.name || 'N/A';
    }
}

@jsonObject
export class DeviceReportResponse extends LosantPagination {
    @jsonArrayMember(Device)
    items: Device[];
}


export const DeviceSerializer = new TypedJSON(Device);
