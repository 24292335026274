import {jsonMember, jsonObject} from "typedjson";

@jsonObject()
export class EventUpdate {
    @jsonMember(String)
    date: string;
    @jsonMember(String)
    message: string;
    @jsonMember(String)
    user: string;
}
