import React, { useCallback, useMemo, useState } from 'react';
import PaginatedList from "../../components/paginated-list";
import { Button, Form, Input, message, Popconfirm, Space, Tooltip } from "antd";
import { IconFilter } from "../../assets/icons/icon-filter";
import useTablePagination from "../../utils/useTablePagination";
import useToggleValue from "../../utils/useToggleValue";
import { useNavigate } from "react-router-dom";
import { EGroupType, getRouteForGroupType } from "../../contracts/EGroupType";
import ColumnConfigurator from "../../components/paginated-list/ColumnConfigurator";
import { EResource } from "../../contracts/EResource";
import {
    useDeleteVehicleMutation,
    useGetPaginatedVehiclesListQuery,
    useUpdateVehicleMutation
} from "../../redux/services/losant/vehicles";
import { Vehicle, VehicleSerializer } from "../../contracts/holotrak/vehicle";
import AccessControl from "../../components/core/AccessControl";
import { EPermission } from "../../contracts/EPermission";
import iconDelete from "../../assets/svg/icon-delete.svg";
import iconEdit from "../../assets/svg/icon-edit.svg";
import VehicleForm from "../../components/vehicle-form";
import DriversDropdown from "../../components/dropdowns/DriversDropdown";
import { DriverBase } from "../../contracts/holotrak/driverBase";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { useGetGeofenceListQuery } from 'redux/services/losant/geofences';
import { IconVoltage } from 'assets/icons/icon-voltage';


export const VehicleReport: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [updateVehicle, { isLoading: isUpdatingVehicle }] = useUpdateVehicleMutation();
    const [deleteVehicle] = useDeleteVehicleMutation();


    const [isVehicleFormOpen, setIsVehicleFormOpen] = useState<boolean>(false);
    const [currentVehicle, setCurrentVehicle] = useState<Vehicle>(null);

    const [isFilterOpen, toggleFilterOpen] = useToggleValue(false);

    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const {
        searchTerm,
        handleSearch,
        sorting,
        pagination,
        handleTableChange,
    } = useTablePagination({
        pageSize: 30
    });


    const {isLoading: isLoadingUsers, data: geofenceApiResponse} = useGetGeofenceListQuery({
        page: pagination.current - 1,
        limit: pagination.pageSize,
        fieldValue: '',
        searchValue: '',
    });

    const cancelDelete = useCallback(() => {
        return messageApi.open({
            type: "error",
            content: "Cancelled",
        });
    }, [messageApi]);


    const handleVehicleDeleteClicked = useCallback((vehicleId: string) => {
        deleteVehicle(vehicleId);
    }, [deleteVehicle]);

    const addNewVehicle = useCallback(() => {
        setCurrentVehicle(null);
        setIsVehicleFormOpen(true);
    }, [setCurrentVehicle, setIsVehicleFormOpen]);




    const { isLoading, data: tableResponse } = useGetPaginatedVehiclesListQuery({
        page: pagination.current - 1,
        limit: pagination.pageSize,
        sort: sorting.order,
        sortBy: sorting.field,
        fieldValue: 'name',
        searchValue: searchTerm,
    }, {
        selectFromResult: ({ isLoading, data }) => {
            const items = data?.items?.map((item) => VehicleSerializer.parse(item)) || [];
            return {
                data:
                {
                    totalCount: data?.totalCount ?? 0,
                    items: items,
                },
                isLoading
            };
        },
    });


    const records = useMemo(() => {
        return isLoading ? [] : tableResponse.items.filter((item) => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [isLoading, searchTerm, tableResponse]);

    const filterSupport = useMemo(() => {
        return (
            <div className="filter-support">
                <Button type='default' shape='circle' icon={<IconFilter />} onClick={toggleFilterOpen} />
            </div>
        );
    }, [toggleFilterOpen]);

    const closeVehicleForm = useCallback(() => {
        setIsVehicleFormOpen(false);
        setCurrentVehicle(null);
    }, [setIsVehicleFormOpen, setCurrentVehicle]);


    const renderGridContent = useCallback((item: Vehicle) => {
        return !item ? null : (
            <ul>
                <li>
                    <h4>Driver</h4>
                    <p>{item.driver?.name ?? '-'}</p>
                </li>
                <li>
                    <h4>Device</h4>
                    <p>{item.device?.name ?? '-'}</p>
                </li>
                <li>
                    <h4>Type</h4>
                    <p>{item.type ?? '-'}</p>
                </li>
                <li>
                    <h4>Location</h4>
                    <p>{item.location ?? '-'}</p>
                </li>
            </ul>
        );
    }, []);

    const handleSearchField = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        const filteredData = records?.filter((item) =>
            item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase())
        );
        setFilteredData(filteredData);
    };

    const handleReset = (clearFilters: () => void, confirm) => {
        setFilteredData([]);
        setSearchText('');
        clearFilters()
        confirm()
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearchField(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearchField(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters, confirm)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        // filterIcon: (filtered: boolean) => (
        //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        // ),
        onFilter: (value: string, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const [columns, setColumns] = useState([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            column: true,
            sorter: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Driver',
            dataIndex: 'driverId',
            key: 'driver',
            column: true,
            editable: true,
            render: (item, record) => record.driver?.name || '',
            action: (form, saveForm) => (
                <>
                    <Tooltip title={'Un assign'}>
                        <Button icon={<ClearOutlined />} onClick={() => {
                            form.setFieldsValue({ driverId: null });
                            saveForm();
                        }} />
                    </Tooltip>
                </>
            ),
            component: (form, saveForm) => (
                <Form.Item
                    dependencies={['driverId']}
                >
                    {
                        (form) => (
                            <DriversDropdown
                                fieldName={['driverId']}
                                onBlur={saveForm}
                            />
                        )
                    }

                </Form.Item>
            ),
            handleSave: async (row: Vehicle) => {
                let driver = row.driver ?? new DriverBase();
                if (driver.id !== row.driverId) {
                    driver.id = row.driverId;
                    updateVehicle({
                        data: {
                            ...row,
                            driver: driver
                        }
                    }).then(() => {
                        messageApi.success('Vehicle updated successfully');
                    });
                }
            }
        },
        {
            title: 'Device',
            dataIndex: 'device.name',
            key: 'device',
            column: true,
            render: (item, record) => record?.device?.name || '',
        },
        {
            title: 'Voltage',
            dataIndex: 'voltageLevel',
            key: 'voltageLevel',
            column: true,
            render: (item, record) => item || '',
        },
        {
            title: 'External Battery',
            dataIndex: 'externalBatteryLevel',
            key: 'externalBatteryLevel',
            column: true,
            render: (item, record) => item || '',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            column: true,
        },
        {
            title: 'Lot',
            dataIndex: 'lot',
            key: 'lot',
            column: true,
            render: (item, record) => record?.lotName
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            column: false,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            column: false,
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            column: true,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            column: false,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
            column: true,
        },

        {
            title: 'Stock Number',
            dataIndex: 'stockNumber',
            key: 'stockNumber',
            column: false,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            column: true,
        },
        {
            title: 'SubType',
            dataIndex: 'type',
            key: 'type',
            column: true,
        },
        {
            title: 'Insurance Policy Number',
            dataIndex: 'insurancePolicyNumber',
            key: 'insurance2PolicyNumber',
            column: false,
        },

        {
            title: 'Insurance Valid Till',
            dataIndex: 'insuranceValidTill',
            key: 'insuranceValidTill',
            column: false,
        },


        {
            title: 'Insurance 2 Policy Number',
            dataIndex: 'insurance2PolicyNumber',
            key: 'insurance2PolicyNumber',
            column: false,
        },


        {
            title: 'Insurance 2 Valid Till',
            dataIndex: 'insurance2ValidTill',
            key: 'insurance2ValidTill',
            column: false,
        },

        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            column: false,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            column: false,
        },
        {
            title: 'Engine',
            dataIndex: 'engine',
            key: 'engine',
            column: false,
        },
        {
            title: 'Horse Power',
            dataIndex: 'horsePower',
            key: 'horsePower',
            column: false,
        },
        {
            title: 'Transmission',
            dataIndex: 'transmission',
            key: 'transmission',
            column: false,
        },
        {
            title: 'Fuel',
            dataIndex: 'fuel',
            key: 'fuel',
            column: false,
        },
        {
            title: 'Fuel Grade',
            dataIndex: 'fuelGrade',
            key: 'fuelGrade',
            column: false,
        },
        {
            title: 'Fuel Capacity',
            dataIndex: 'fuelCapacity',
            key: 'fuelCapacity',
            column: false,
        },
        {
            title: 'Wheel Diameter',
            dataIndex: 'wheelDiameter',
            key: 'wheelDiameter',
            column: false,
        },
        {
            title: 'Wheel Width',
            dataIndex: 'wheelWidth',
            key: 'wheelWidth',
            column: false,
        },
        {
            title: 'Tyre Number',
            dataIndex: 'tyreNumber',
            key: 'tyreNumber',
            column: false,
        },
        {
            title: 'Tyre size',
            dataIndex: 'tyreSize',
            key: 'tyreSize',
            column: false,
        },
        {
            title: 'Length',
            dataIndex: 'length',
            key: 'length',
            column: false,
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            column: false,
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            column: false,
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            column: false,
        },
        {
            title: 'Passenger Capacity',
            dataIndex: 'passengerCapacity',
            key: 'passengerCapacity',
            column: false,
        },
        {
            title: "ACTIONS",
            dataIndex: "actions",
            key: "actions",
            render: (item, record: Vehicle) => (
                <Space>
                    <AccessControl
                        resource={EResource.VEHICLES}
                        permissionNeeded={EPermission.DELETE}
                        sidebar={true}
                        render={
                            accessProps => (
                                <Popconfirm
                                    {...accessProps}
                                    title="Delete this record?"
                                    onConfirm={() => handleVehicleDeleteClicked(record.id)}
                                    onCancel={cancelDelete}
                                    okText="Yes"
                                    cancelText="NO"
                                >
                                    <Tooltip title="Delete Vehicle">
                                        <div style={{ float: "left", cursor: "pointer" }}>
                                            <img src={iconDelete} alt="DeleteGroupIcon" />
                                        </div>
                                    </Tooltip>
                                </Popconfirm>
                            )
                        } />

                    <AccessControl
                        resource={EResource.VEHICLES}
                        permissionNeeded={EPermission.UPDATE}
                        render={() => (
                            <Tooltip title="Edit Vehicle">
                                <div style={{ float: "left", cursor: "pointer" }}>
                                    <img
                                        src={iconEdit}
                                        alt="EditGroupIcon"
                                        onClick={() => {
                                            setCurrentVehicle(record);
                                            setIsVehicleFormOpen(true);
                                        }} />
                                </div>
                            </Tooltip>
                        )}
                    />
                </Space>
            ),
        },
    ]);


    return (
        <>
            {contextHolder}
            <PaginatedList
                title="All Vehicles"
                resource={EResource.VEHICLES}
                onSearch={handleSearch}
                pagination={pagination}
                onChange={handleTableChange}
                isLoading={isLoading}
                dataSource={filteredData.length > 0 ? filteredData : records}
                columns={columns}
                additionalHeader={filterSupport}
                totalCount={tableResponse?.totalCount}
                showTotal={true}
                hasGridView={true}
                gridConfig={{
                    title: (item: Vehicle) => item?.name,
                    onDelete: (item: Vehicle) => handleVehicleDeleteClicked(item.id),
                    onEdit: (item: Vehicle) => {
                        setCurrentVehicle(item);
                        setIsVehicleFormOpen(true);
                    },
                    onCancel: () => cancelDelete(),
                    gridContent: (item: Vehicle) => renderGridContent(item)
                }}


                beforeExportSlot={(
                    <Button shape='round' onClick={addNewVehicle} className='add-new-vehicle'>
                        Add New Vehicle
                    </Button>
                )}

                afterContent={(
                    <div className='bottom-bar'>
                        <Space size={24}>
                            <Button
                                size='small'
                                onClick={() => {
                                    navigate(`/${getRouteForGroupType(EGroupType.VEHICLE)}`);
                                }}
                            >
                                Map
                            </Button>
                        </Space>
                    </div>
                )}
            />
            <ColumnConfigurator
                isConfiguring={isFilterOpen}
                toggleConfigurationMode={toggleFilterOpen}
                columns={columns}
                setColumns={setColumns}
            />


            <VehicleForm
                isOpen={isVehicleFormOpen}
                onClose={closeVehicleForm}
                vehicle={currentVehicle}
            />

        </>
    );
};

export default VehicleReport;
