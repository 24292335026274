import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import {LosantPagination} from "./losantPagination";
import dayjs from "dayjs";
import {IIndoorLocatableItem} from "../IOutdoorLocatable";
import {IndoorAssetSerializer} from "./IndoorAsset";


@jsonObject
export class IndoorLayout {

    @jsonMember(String)
    name?: string;

    @jsonMember(String)
    layout?: string;

    @jsonMember(String)
    mapId?: string;

    @jsonMember(Number)
    width?: number;

    @jsonMember(Number)
    height?: number;

    @jsonMember(String)
    organisationId?: string;

    @jsonMember(String)
    notes?: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(String)
    id?: string;

    private _locatableItems: IIndoorLocatableItem[] = [];

    get locatableItems(): IIndoorLocatableItem[] {
        // ANTI-PATTERN since we are locating devices only.
        return this._locatableItems.map(
            (item) => IndoorAssetSerializer.parse(item)
        );
    }

    set locatableItems(value: IIndoorLocatableItem[]) {
        this._locatableItems = value;
    }

    get label(): string {
        return `${this.name}`;
    }
}

@jsonObject
export class IndoorLayoutListResponse extends LosantPagination {
    items: IndoorLayout[];
}

export const indoorLayoutSerializer = new TypedJSON(IndoorLayout);
